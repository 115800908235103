import { Link, useNavigate } from "react-router-dom";
import styles from "../../../styles/component-styles/customer/layout.module.scss";
import home_ic from "../../../assets/icons/home.svg";
import song_ic from "../../../assets/icons/song.svg";
import video_ic from "../../../assets/icons/video.svg";
import playlist_ic from "../../../assets/icons/playlist.svg";
import paytip_ic from "../../../assets/icons/paytip.svg";
import logout_ic from "../../../assets/icons/logout.svg";
import contact_ic from "../../../assets/icons/contact-us.svg";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTab } from "../../../redux/slice/ui/ui-slice";
import { logoutUser } from "../../../redux/slice/auth/auth-slice";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { constants } from "../../../constants";
import close_ic from "../../../assets/icons/close-ic.svg";
import logo from "../../../assets/icons/logo-white.svg";
import legal_ic from "../../../assets/icons/legal.svg"
const clientSidebarItems = [
    {
      title: 'home',
      icon: home_ic,
      path: '/customer',
      external: false,
    },
    {
      title: 'songs',
      icon: song_ic,
      path: '/customer/songs',
      external: false,
    },
    {
        title: 'new songs',
        icon: song_ic,
        path: '/customer/new-songs',
        external: false,
      },
    {
      title: 'playlist',
      icon: playlist_ic,
      path: '/customer/playlist',
      external: false,
    },
    {
      title: 'videos',
      icon: video_ic,
      path: '/customer/videos',
      external: false,
    },
    {
      title: 'paytip',
      icon: paytip_ic,
      path: '/customer/paytip',
      external: false,
    },
    {
      title: 'contact',
      icon: contact_ic,
      path: '/customer/contact-us',
      external: false,
    },
    {
      title: 'legal notice',
      icon: legal_ic,
      path: '/customer/legal-notice',
      external: false,
    },
  ];
  
  export function Sidebar() {
    const active = useSelector(selectTab);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const language = useSelector(selectLanguage);
  
    const logout = () => {
      dispatch(logoutUser({}));
      navigate('/');
    };
  
    return (
      <div className={styles.sidebar}>
        <div className={styles.top}>
          <div className={styles.logo}>
            <h1 onClick={() => navigate('/customer')}>
              <img src={logo} alt="Logo" />
            </h1>
          </div>
          <hr />
          <ul className={styles.menu}>
            {clientSidebarItems.map((item) => (
              <li
                key={item.title}
                className={`${styles.menuItem} ${
                  active.toLowerCase() === item.title.toLowerCase() ? styles.active : ''
                }`}
                onClick={() => {
                  if (item.external) {
                    window.open(item.link, '_blank');
                  } else {
                    navigate(item.path);
                  }
                }}
              >
                <img src={item.icon} alt={`${item.title} icon`} />
                <Link>{constants[language][item.title]}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.bottom}>
          <div onClick={logout}>
            <img src={logout_ic} alt="Logout" />
            <span className={styles.logout}>{constants[language].logout}</span>
          </div>
        </div>
      </div>
    );
  }
  
  export function MobileSidebar({ isOpen, setOpen }) {
    const active = useSelector(selectTab);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const language = useSelector(selectLanguage);
    const ref = useRef(null);
  
    useEffect(() => {
      if (isOpen) {
        ref.current.style.left = '0';
      } else {
        ref.current.style.left = '-100%';
      }
    }, [isOpen]);
  
    const logout = () => {
      dispatch(logoutUser({}));
      navigate('/');
      setOpen(false);
    };
  
    return (
      <div className={styles.mobileSidebar} ref={ref}>
        <div className={styles.top}>
          <div className={styles.logo}>
            <h1
              onClick={() => {
                navigate('/customer');
                setOpen(false);
              }}
            >
              <img src={logo} alt="Logo" />
            </h1>
            <span onClick={() => setOpen(false)}>
              <img src={close_ic} alt="Close" />
            </span>
          </div>
          <hr />
          <ul
            className={styles.menu}
            onClick={() => setOpen(false)}
          >
            {clientSidebarItems.map((item) => (
              <li
                key={item.title}
                className={`${styles.menuItem} ${
                  active.toLowerCase() === item.title.toLowerCase() ? styles.active : ''
                }`}
                onClick={() => {
                  if (item.external) {
                    window.open(item.link, '_blank');
                  } else {
                    navigate(item.path);
                  }
                  setOpen(false);
                }}
              >
                <img src={item.icon} alt={`${item.title} icon`} />
                <Link>{constants[language][item.title]}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.bottom}>
          <div onClick={logout}>
            <img src={logout_ic} alt="Logout" />
            <span className={styles.logout}>{constants[language].logout}</span>
          </div>
        </div>
      </div>
    );
  }
  