import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { apiDELETE, apiGET, apiPOST_File } from "../../../apis/service";
import { toast } from "react-toastify";

export const getAllUsers = () => {
    return apiGET("api/v1/users", { all: true })
}

export const getUsersAsync = createAsyncThunk(
    "user/get/users",
    async ({ page = 1, limit = 10, search = "", sortBy = undefined, all = false }, thunkAPI) => {
        try {
            const params = {}
            if (search) params.search = search;
            if (sortBy) params.sortBy = sortBy;
            if (all) params.all = true;
            if (page) params.page = page;
            if (limit) params.limit = limit;

            thunkAPI.dispatch(getUsersStart());
            const response = await apiGET("api/v1/users", params);
            if (response.success) {
                thunkAPI.dispatch(getUsersSuccess(response));
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)

export const searchUsersAsync = createAsyncThunk(
    "users/search",
    async ({ search, page = 1, limit = 10, sortBy = undefined, all = false }, thunkAPI) => {
        try {
            thunkAPI.dispatch(searchUsersStart());
            const response = await apiGET("api/v1/users", { search, page, limit, sortBy, all });

            if (response.success)
                thunkAPI.dispatch(searchUsersSuccess(response));
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)

export const getUserAsync = createAsyncThunk(
    "user/getbyId",
    async ({ userId }, thunkAPI) => {
        try {
            thunkAPI.dispatch(getUserStart());
            const response = await apiGET(`api/v1/users`, { userId });
            if (response.success) {
                thunkAPI.dispatch(getUserSuccess(response));
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)

const userSlice = createSlice({
    name: "user",
    initialState: {
        loading: false,
        error: null,
        user: null,
        users: null,
    },
    reducers: {
        getUsersStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        getUsersSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.users = action.payload;
        },

        getUserStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        getUserSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.user = action.payload.user;
        },
        searchUsersStart: (state) => {
            state.loading = true;
            state.error = null;
            state.users = null;
        },
        searchUsersSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.users = action.payload.users;
        },


    }
})

export const {
    getUsersStart, getUsersSuccess,
    getUserStart, getUserSuccess,
    searchUsersStart, searchUsersSuccess
} = userSlice.actions;
export const selectUserData = state => state.user.user;
export const selectUsersData = state => state.user.users;

// loading
export const selectUsersLoading = state => state.user.loading;

export default userSlice.reducer