import React from 'react';
import styled from 'styled-components';


const Centered = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

function Center({ children, ...props }) {
    return (
        <Centered {...props}>
            {children}
        </Centered>
    )
}

export default Center