// theme.js
export const theme = {
    colors: {
      primary: '#EE4950',
      secondary: '#AFB6B2',
      primaryText: '#fafafa',
      secondaryText: '#7A8580',
    },
    typography: {
      fontFamily: 'Inter',
      fontSizeXsm: '12px',
      fontSizeSm: '14px',
      fontSizeBase: '16px',
      fontSizeLg: '18px',
      fontSizeXlg: '22px',
      fontWeightNormal: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
      fontWeightXBold: 700,
    },
    spacing: {
      xs: '4px',
      sm: '8px',
      md: '16px',
      lg: '24px',
      xl: '32px',
    },
    borderRadius: {
      sm: '4px',
      md: '8px',
      lg: '12px',
    },
    transition: {
      fast: '0.2s ease-in-out',
      normal: '0.3s ease-in-out',
      slow: '0.5s ease-in-out',
    },
  };
  