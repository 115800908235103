import React from "react";
import RoutesContainer from "./router/index";
import { Provider } from "react-redux";
import "./app.css";
import store from "./redux/store";
function App() {
  return (
    <Provider store={store}>
      <RoutesContainer />
    </Provider>
  );
}

export default App;
