export const spanish = {
    songs: "Canciones",
    playlist: "Mi Lista de Canciones",
    videos: "Videos",
    search: "Buscar",
    clear: "Limpiar",
    ads: "Anuncios",
    logout: "Cerrar Sesión",
    "Top Searched songs": "Canciones Más Buscadas",
    "upload music": "Subir Música",
    "new songs": "Nuevas canciones",
    "playlist": "Mi Lista de Canciones",
    "ads": "Anuncios",
    "upload video": "Subir Video",
    "videos": "Videos",
    "legal notice": "Aviso Legal",
    start_upload: "Comienza subiendo un archivo",
    upload_description: "Cualquier recurso utilizado en proyectos vivirá aquí. Comienza a crear subiendo tus archivos.",
    upload_btn: "Subir",
    no: "No.",
    title: "Título",
    artist: "Artista",
    language: "Idioma",
    action: "Acción",
    add_to_playlist: "Agregar A Mi Lista de Canciones",
    remove_from_playlist: "Eliminar De Mi Lista de Canciones",
    sr: "N°",
    no_data: "No Se Encontraron Datos",
    created_on: "Creado El",
    expired_on: "Caducado El",
    status: "Estado",
    loading: "Cargando Datos ...",
    actions: "Acciones",
    create_ad: "Crear un Anuncio",
    no_data_search: "No se encontraron canciones. Escribe el título de la canción Y el nombre del artista en la búsqueda",
    create_ad_description: "Agrega detalles para publicar tu anuncio.",
    click_upload: "Haz clic para subir",
    drag_drop: "o arrastra y suelta",
    file_type: "SVG, PNG, JPG o GIF (máx. 800x400px)",
    ad_title: "Título del Anuncio",
    description: "Descripción",
    ad_expiry: "Caducidad del Anuncio",
    characters: "Caracteres",
    cancel: "Cancelar",
    publish: "Publicar",
    ad_description: "Descripción del Anuncio",
    active: "Activo",
    close: "Cerrado",
    // customer
    home: "Inicio",
    paytip: "Dar Propina",
    "New Releases": "Nuevos Lanzamientos",
    recent_added: "Recientemente Añadido",
    close_ad: "Cerrar Anuncio",
    "Home": "Inicio",
    "Search Songs": "Buscar Canciones",
    "Search Playlist": "Buscar Lista de Canciones",
    "Paytip": "Dar Propina",
    "Contact Us": "Contáctanos",
    users: "usuarias",
    leave_a_tip: "Deja una propina a través de PayPal",
    leave_a_tip_description: "Apoya nuestra aplicación dejando una propina voluntaria a través de PayPal",
    pay_btn: "Pagar con Stripe",
    powered_by: "desarrollado por Felix",
    upload_new_btn: "Subir Nuevo",
    thumbnail: "Miniatura",
    payment_required: "Para ver el video completo, necesitas realizar un pago.",
    contact: "Contáctanos",
    newestToOldest: "De Nuevo a Antiguo",
    oldestToNewest: "De Antiguo a Nuevo",
    atoZ: "De la A a la Z",
    ztoA: "De la Z a la A",
    sort_by: "Ordenar Por",
    responsible_for: "Responsable de esta Página",
    desclaimer: "Aviso",
    desclaimer_desc: "La reproducción de contenido externo no se hace responsable. El contenido de las páginas relacionadas de la solo responsabilidad de sus creadores.",
    privacy_policy: "Politica de Privacidad",
    privacy_policy_desc: "Nuestro sitio web hace uso de las tecnologías de la información y de las garantías de privacidad de la información. Estas se explican en esta página.",
    usage_data: "Datos de Uso",
    usage_data_descriptions: [
        "Cada vez que se accede al sitio web y cada vez que se recupera un archivo, los datos generales sobre este proceso se almacenan automáticamente en un archivo de registro. El almacenamiento sirve exclusivamente para fines estadísticos y relacionados con el sistema (basado en el Art. 6, apartado 1, letra b) del RGPD), así como en casos excepcionales para denunciar delitos penales (basado en el Art. 6, apartado 1, letra e) del RGPD).",
        "No se produce ninguna transferencia de datos a terceros ni otras evaluaciones, a menos que exista una obligación legal para hacerlo (Art. 6, apartado 1, letra e) del RGPD)."
    ],
    usage_data_bullets_heading: "Específicamente, se almacena el siguiente conjunto de datos para cada recuperación",
    usage_data_bullets: [
        "Nombre del archivo recuperado",
        "Fecha y hora de la recuperación",
        "Cantidad de datos transferidos",
        "Notificación de si la recuperación fue exitosa",
        "Descripción del tipo de navegador web utilizado",
        "Sistema operativo utilizado",
        "La página visitada anteriormente",
        "Proveedor",
        "Su dirección IP"
    ],
    personal_data: "Datos personales",
    personal_data_desc: "Los datos personales solo se procesan si lo permite la ley o si usted ha dado su consentimiento.",
    specifically: "Específicamente",
    personal_data_bullets: [
        {
            heading: "Contacto",
            descriptions: [
                "Cuando se pone en contacto con nosotros, almacenamos sus datos de acuerdo con el Art. 6, apartado 1, letra b) del RGPD para procesar su solicitud, así como en caso de que se produzca una correspondencia posterior."
            ]
        },
        {
            heading: "Función de comentarios",
            descriptions: [
                "Tiene la oportunidad de dejar comentarios individuales en publicaciones específicas de blogs o libros de visitas publicadas en nuestro sitio web.",
                "Si deja un comentario de este tipo, además del contenido del propio comentario, también se almacena y publica la hora de entrada del comentario.",
                "Además, también se registrará su dirección IP. Esto se hace con fines de prueba y documentación en caso de que seamos responsables de alguna infracción legal debido a su comentario. Por lo tanto, el procesamiento de datos es necesario para salvaguardar nuestros intereses legítimos de conformidad con el Art. 6, apartado 1, letra f) del RGPD.",
                "Sus datos personales solo se transmitirán a terceros si estamos legalmente obligados a hacerlo (entonces sobre la base del Art. 6, apartado 1, letra e) del RGPD), o si esto es necesario para proteger los intereses legítimos del tercero (entonces sobre la base del Art. 6, apartado 1, letra f) del RGPD)."
            ]
        }
    ],
    cookies: "Cookies",
    cookies_descriptions: [
        "Utilizamos cookies en algunas áreas de nuestro sitio web. Estos elementos de archivo pueden identificar su computadora como una unidad técnica durante su visita a este sitio web para facilitarle el uso de nuestras ofertas en visitas repetidas.",
        "Sin embargo, normalmente tiene la opción de configurar su navegador de internet para que le informe sobre la aparición de cookies, de modo que pueda permitirlas o excluirlas, o eliminar las cookies ya existentes.",
        "Utilice la función de ayuda de su navegador de internet para obtener información sobre cómo cambiar esta configuración. Tenga en cuenta que algunas funciones de nuestro sitio web pueden no funcionar si ha deshabilitado el uso de cookies.",
        "Las cookies no permiten a un servidor leer datos privados de su computadora o datos almacenados por otro servidor. No causan ningún daño a su computadora y no contienen virus.",
        "Basamos el uso de cookies en el Art. 6, apartado 1, letra f) del RGPD: el procesamiento se lleva a cabo para mejorar la funcionalidad de nuestro sitio web. Por lo tanto, es necesario para salvaguardar nuestros intereses legítimos."
    ],
    usage_social_media: "Uso de plugins sociales de Facebook, Twitter, Google+ e Instagram",
    usage_social_media_descriptions: [
        "La protección de sus datos personales es muy importante para nosotros. Por lo tanto, no implementamos directamente los diversos plugins sociales.",
        "En nuestras páginas, solo configuramos enlaces a estas redes sociales. Los datos solo se transferirán si sigue los enlaces y se activan los plugins sociales correspondientes.",
        "Tenga en cuenta que los datos solo se transfieren cuando activa los plugins sociales correspondientes haciendo clic en el enlace correspondiente. La simple visita a nuestras páginas no da como resultado ninguna transmisión de datos."
    ],
    usage_social_media_bullets_heading: "Si activa la transmisión de datos haciendo clic en el enlace correspondiente, se producirá la siguiente transferencia de datos",
    usage_social_media_bullets: [
        {
            heading: "Facebook",
            descriptions: [
                `Los plugins de la red social Facebook (Facebook Inc., 1601 Willow Road, Menlo Park, CA 94025, EE. UU.) se integran luego en nuestro sitio. Puede reconocer los plugins de Facebook por el logotipo de Facebook o el botón "Me gusta" ("Me gusta") en nuestro sitio. Puede encontrar una descripción general de los plugins de Facebook aquí: http://developers.facebook.com/docs/plugins/`,
                `Se establece una conexión directa entre su navegador y el servidor de Facebook a través del complemento. De este modo, Facebook recibe la información de que ha visitado nuestro sitio con su dirección IP. Si hace clic en el botón "Me gusta" de Facebook mientras está conectado a su cuenta de Facebook, puede vincular el contenido de nuestro sitio a su perfil de Facebook. Esto permite a Facebook asociar la visita a nuestro sitio con su cuenta.`,
                `Señalamos que nosotros, como operadores del sitio, no tenemos ningún conocimiento del contenido de los datos transmitidos o su uso por parte de Facebook. Puede encontrar más información en la política de privacidad de Facebook en http://es-es.facebook.com/policy.php.`,
                `Si no desea que Facebook asocie su visita a nuestro sitio con su cuenta de Facebook, cierre la sesión de su cuenta de Facebook o no active los complementos sociales.`
            ]
        },
        {
            heading: "Google+1",
            descriptions: [
                "Las funciones del servicio Google+ están integradas en nuestro sitio. Estas funciones son proporcionadas por Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, EE. UU.",
                "Al usar Google+ y el botón de Google, el navegador establece una conexión directa con los servidores de Google. El contenido del botón de Google se transmite directamente de Google a su navegador y se integra en el sitio web.",
                "No tenemos influencia sobre la cantidad de datos que recopila Google con el botón.",
                "Para obtener información sobre el propósito y el alcance de la recopilación de datos y el procesamiento y uso posterior de los datos por parte de Google, así como sus derechos y opciones de configuración para proteger su privacidad, consulte el aviso de privacidad de Google sobre el botón de Google: http://www.google.com/intl/es/+/policy/+1button.html y las preguntas frecuentes: http://www.google.com/intl/es/+1/button/."
            ]
        }
    ],
    use_pulgin_youtube: "Uso de Complementos Sociales de YouTube",
    use_pulgin_youtube_descriptions: [
        "Nuestro sitio web también enlaza a la red social YouTube. También operamos una página de redes sociales aquí.",
        "Si hace clic en un enlace a YouTube, será redirigido a la página externa correspondiente en YouTube. Si también está conectado como miembro de YouTube, el operador, YouTube LLC, 901 Cherry Ave., San Bruno, CA 94066, EE.UU., puede asignar su visita a nuestra página a su cuenta de usuario respectiva. Señalamos que YouTube LLC es parte de Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, EE.UU.",
        "Si no desea que YouTube recopile y almacene datos sobre su visita a nuestro sitio web, debe cerrar sesión en su cuenta de YouTube antes de hacer clic en el enlace.",
        "Para obtener información sobre el propósito y el alcance de la recopilación de datos y el procesamiento y uso adicional de sus datos por parte de YouTube, consulte la política de privacidad correspondiente, que puede encontrar aquí: http://www.google.de/intl/de/policies/privacy/.",
        "Basamos el uso de YouTube en el Art. 6(1)(a) GDPR. Al hacer clic en el enlace mientras está simultáneamente conectado a YouTube, nos da su consentimiento para el procesamiento de datos. En cualquier caso, el procesamiento de datos es permisible conforme al Art. 6(1)(f) GDPR; al hacer clic en el enlace mientras está conectado a la red social respectiva, el procesamiento de datos se realiza con fines publicitarios y, por lo tanto, es de nuestro interés legítimo."
    ],
    usage_google_utility: "Uso de Google Web Fonts, Google Maps y OpenStreetMap",
    usage_google_utility_bullets: [
        {
            heading: "Uso de Google Web Fonts y Google Maps",
            descriptions: [
                "Nuestro sitio web utiliza fuentes externas, las llamadas Google Web Fonts. También utilizamos Google Maps para mostrar las direcciones a nuestra empresa y simplificar su planificación de viajes.",
                "Utilizamos servicios de Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, EE.UU. para este propósito.",
                "Cuando usted accede a nuestra página, su navegador carga la información necesaria desde el servidor de Google en los EE.UU. en su caché del navegador. Esto es necesario para que su navegador muestre una representación visual mejorada de nuestros textos, o para que el mapa se muestre en nuestro sitio web.",
                "Esto transmite al servidor de Google cuál de nuestras páginas de Internet ha visitado. Su dirección IP también es almacenada por Google.",
                "Más información sobre Google Web Fonts se puede encontrar en https://developers.google.com/fonts/faq?hl=es-ES&csw=1.",
                "Más información sobre Google Maps se puede encontrar en https://www.google.com/intl/es_es/help/terms_maps.html.",
                "Información general sobre el tema de la protección de datos en Google se puede encontrar en www.google.com/policies/privacy/"
            ]
        },
        {
            heading: "Uso de Google Analytics",
            descriptions: [
                "Este sitio web utiliza Google Analytics, un servicio de análisis web proporcionado por Google Inc.",
                "Google Analytics utiliza archivos de texto almacenados en su computadora para ayudar a analizar cómo utiliza el sitio web.",
                "La información generada por la cookie sobre su uso de este sitio web generalmente se transmite y almacena en servidores de Google en los EE.UU.",
                "Si se activa la anonimización de IP en este sitio web, su dirección IP será truncada por Google dentro de los Estados miembros de la Unión Europea o en otros estados que son parte del Acuerdo sobre el Espacio Económico Europeo.",
                "Solo en casos excepcionales se transmitirá la dirección IP completa a un servidor de Google en los EE.UU. y se truncará allí.",
                "En nombre del operador de este sitio web, Google utilizará esta información para evaluar su uso del sitio web, compilar informes sobre la actividad del sitio web y proporcionar otros servicios relacionados con el uso del sitio web y de Internet al operador del sitio web.",
                "La dirección IP transmitida por su navegador como parte de Google Analytics no se fusionará con otros datos de Google.",
                "Puede rechazar el uso de cookies seleccionando la configuración apropiada en su navegador. Sin embargo, tenga en cuenta que si hace esto, es posible que no pueda utilizar la funcionalidad completa de este sitio web.",
                "También puede evitar la recopilación de datos generados por la cookie sobre su uso del sitio web (incluida su dirección IP) por parte de Google y el procesamiento de estos datos por parte de Google descargando e instalando el complemento del navegador disponible en http://tools.google.com/dlpage/gaoptout?hl=es."
            ]
        }
    ],
    duration_storage: "Duración del Almacenamiento",
    duration_storage_desc: "Almacenamos sus datos durante el tiempo que sea necesario para el procesamiento final de su solicitud. Se excluyen de esto los datos para los que existen obligaciones de retención legales u otras prescritas; estos se almacenan durante el período de retención respectivo y luego se eliminan de forma rutinaria.",
    rights_data_subject: "Derechos del Sujeto de Datos",
    rights_data_subject_bullets: [
        {
            heading: "Derecho de Acceso",
            description: "Tiene derecho a solicitar la confirmación de si se están procesando datos personales que le conciernen.",
            footer: "Para hacerlo, simplemente envíe un correo electrónico a bobfel (at) arcor.de"
        },
        {
            heading: "Rectificación/Eliminación/Restricción del Procesamiento",
            description: "También tiene derecho a solicitarnos que",
            bullets: [
                "los datos personales incorrectos que le conciernen se corrijan sin demora (derecho de rectificación);",
                "los datos personales que le conciernen se eliminen sin demora (derecho de eliminación); y",
                "el procesamiento sea restringido (derecho de restricción del procesamiento)."
            ],
            footer: "Para hacerlo, simplemente envíe un correo electrónico a bobfel (at) arcor.de"
        },
        {
            heading: "Derecho a la Portabilidad de los Datos",
            description: "Tiene derecho a recibir los datos personales que le conciernen, que nos ha proporcionado, en un formato estructurado, de uso común y legible por máquina, y a transmitir esos datos a otro responsable del tratamiento.",
            footer: "Para hacerlo, simplemente envíe un correo electrónico a bobfel (at) arcor.de"
        },
        {
            heading: "Derecho a Retirar el Consentimiento",
            description: "Tiene derecho a retirar su consentimiento en cualquier momento. La retirada del consentimiento no afectará la licitud del procesamiento basado en el consentimiento antes de su retirada.",
            footer: "Para hacerlo, simplemente envíe un correo electrónico a bobfel (at) arcor.de"
        },
        {
            heading: "Derecho a Oponerse",
            description: "Cuando el procesamiento de datos personales que le conciernen sea necesario para el cumplimiento de una tarea realizada en interés público (Art. 6(1)(e) GDPR) o para los fines de intereses legítimos (Art. 6(1)(f) GDPR), tiene derecho a oponerse a dicho procesamiento.",
            footer: "Para hacerlo, simplemente envíe un correo electrónico a bobfel (at) arcor.de"
        },
        {
            heading: "Derecho a Presentar una Queja",
            description: "Si cree que el procesamiento de datos personales que le conciernen viola el GDPR, tiene derecho a presentar una queja ante una autoridad de control, sin perjuicio de cualquier otro recurso legal.",
        }
    ],
    security: "Seguridad",
    security_desc: "Utilizamos las últimas tecnologías de Internet para la seguridad de sus datos. Para almacenar sus datos de forma segura, nuestros sistemas están protegidos por cortafuegos que evitan el acceso no autorizado desde el exterior.",
    video_selected: "video seleccionado",
    "Reports": "Informes",
    video_title: "Título del video",
    downloads: "Descargas",
    views: "Vistas",
    uploaded_on: "Subido el",
    export_to_csv: "Exportar a CSV",
    emails: "Correos electrónicos",
    reports: "Informes",
    download: "Descargar",
    download_by: "Descargado por",
    download_on: "Descargado el",
    view_by: "Visto por",
    view_on: "Visto el",
    labels: {
        name: "Nombre",
        joining_date: "Fecha de incorporación",
        email: "Correo electrónico",
        date_or_time: "Date/Time",
        subject: "Asunto",
        body: "Cuerpo",
        recipients: "Destinatarias",
        attachments: "Archivos adjuntos",
        send: "Enviar",
        cancel: "Cancelar",
        clear: "Borrar",
        delete: "Eliminar",
        search: "Buscar",
        select_all: "Seleccionar todo",
        unselect_all: "Deseleccionar todo",
        view_all: "Alle anzeigen",
        view_less: "Ver menos",
        view: "vista",
        download: "Descargar",
        compose_email: "Redactar correo",
        load_more: "Cargar más",
        new_email: "Nuevo correo",
        email_preview: "Vista previa del correo",
        no_record_found: "No se encontró ningún registro",
        refech: "Refech",
        export_to_csv: "Exportar a CSV",

    },
    placeholders: {
        email_subject: "Asunto del correo",
        email_content: "Contenido del correo aquí",
        recipients: "Introducir destinatarios",
        search_placeholder_songs: "Search artists, songs, albums...",
        attachments_dragdrop_msg: "Arrastre y suelte archivos aquí o haga clic para seleccionar archivos",
        attachments_size_msg: "Tamaño máximo de carga (20 MB)",
        attachments_support_files_msg: "Archivos soportados: .jpg, .png, .gif, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .mp3, .mp4",
        search_placeholder_video: "Buscar videos...",
    },
    errors: {
        email_subject_error: "El asunto no puede estar vacío",
        email_body_error: "El cuerpo del correo no puede estar vacío",
        participants_error: "Los participantes no pueden estar vacíos"
    }
}