import { addEmailEditorAttachment } from "../redux/slice/email/email-slice";

function CustomUploadAdapter(loader, dispatch, purpose) {
  let BASE_URL = process.env.REACT_APP_BACKEND_URL ?? "http://localhost:4000";
  return {
    upload() {
      return loader.file.then(
        (file) =>
          new Promise(async (resolve, reject) => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("purpose", purpose);
            try {


              const response = await fetch(
                `${BASE_URL}/api/v1/general/upload-image-to-cloud`,
                {
                  method: "POST",
                  body: formData,
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("token"),
                  },
                }
              );

              if (!response.ok) {
                throw new Error("Image upload failed");
              }

              const res = await response.json();
              if (!res.success) {
                throw new Error(res.message);
              }

              
              dispatch(addEmailEditorAttachment(res.imageUrl));
              resolve({ default: res.imageUrl });
            } catch (e) {
              reject(e);
            }
          })
      );
    },

    abort() {
      // Handle upload abort
    },
  };
}

export default function MyCustomUploadAdapterPlugin(editor) {
  // Retrieve additional parameters from the editor's configuration
  // const dispatch = editor.config.get('dispatch');
  const additionalParams = editor.config.get('additionalParams');
  const dispatch = additionalParams.dispatchFun
  const purpose = additionalParams.purpose
  // Use the parameters as needed (e.g., in the file repository setup)
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return CustomUploadAdapter(loader, dispatch, purpose);
  };
}

