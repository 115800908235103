import styles from '../../../../styles/component-styles/admin/compose-email-modal.module.scss';
import { Overlay } from './../overlay';
import close_ic from "../../../../assets/icons/close.svg";
import { useDispatch, useSelector } from 'react-redux';
import { selectAd } from '../../../../redux/slice/ad/ad-slice';
import { selectLanguage } from '../../../../redux/slice/language/laguage.slice';
import { useEffect, useState } from 'react';
import { LoadingButton } from '../../../general/button/LoadingButton';
import WriteEmailScreen from './WriteEmailScreen';
import { createEmailAsync, getEmailAsync, getEmailsAsync, resetEmailEditorAtachment, resetEmailEditorRemovedImagesUrls, resetEmailErrors, selectCreateEmailErrors, selectCreateingEmailLoading, selectEmailEditorImagesUrls, selectEmailRemovedImagesUrls, updateEmailErrors } from '../../../../redux/slice/email/email-slice';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../../utils/constants';
import { constants } from '../../../../constants';
import { IconButton } from '../../../general/button/IconButton';
export function EmailCompose({ isOpen, onClose = (props) => { alert("default handler") } }) {
    const dispatch = useDispatch();
    const language = useSelector(selectLanguage);
    const emailEditorRemovedImages = useSelector(selectEmailRemovedImagesUrls)
    const emailEditorInsideAatachments = useSelector(selectEmailEditorImagesUrls)

    const [emailSubject, setEmailSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);


    const isEmailCreationLoading = useSelector(selectCreateingEmailLoading);
    const createEmailErrors = useSelector(selectCreateEmailErrors);
    useEffect(() => {
        // Clean up the state variables
        return () => {
            resetEveryThing()
        };
    }, []);

    const handleClose = async () => {
        if (emailEditorInsideAatachments?.length > 0 || emailEditorRemovedImages?.length > 0) {
            const imagesToRemove = emailEditorRemovedImages.concat(emailEditorInsideAatachments);

            const response = await fetch(
                `${BASE_URL}/api/v1/general/remove-images-from-cloud`,
                {
                    method: "POST",
                    body: JSON.stringify({ images: imagesToRemove }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Image upload failed");
            }


        }
        resetEveryThing()
        onClose({ isCreated: false });
    }

    const resetEveryThing = () => {
        dispatch(resetEmailErrors());
        dispatch(resetEmailEditorRemovedImagesUrls())
        dispatch(resetEmailEditorAtachment())
        setEmailBody('');
        setEmailSubject('');
        setSelectedParticipants([]);
        setSelectedFiles([]);
        dispatch(resetEmailErrors());
    }
    const validateFields = () => {
        // Validate participants
        if (selectedParticipants.length === 0) {
            dispatch(updateEmailErrors({ participants: 'At least one participant is required.', }));
            return false;
        } else {
            dispatch(updateEmailErrors({ participants: null }));
        }

        // Validate subject
        if (emailSubject.trim() === '') {
            dispatch(updateEmailErrors({ subject: 'Subject is required.' }));
            return false;
        } else {
            dispatch(updateEmailErrors({ subject: null }));
        }


        // validate email body
        if (emailBody.trim() === '') {
            dispatch(updateEmailErrors({ body: 'Email body is required' }));
            return false;
        } else {
            dispatch(updateEmailErrors({ body: null }));
        }

        return true;
    };

    const handleCreateEmail = () => {
        // Run validation
        if (!validateFields()) {
            return;
        }

        const formData = new FormData();

        // Append each file to the FormData
        selectedFiles.forEach((file) => {
            formData.append(`files`, file);
        });

        formData.append("subject", emailSubject);
        formData.append("body", emailBody);
        formData.append("participants", JSON.stringify(selectedParticipants)); // Send as comma-separated values
        formData.append("status", "send");
        formData.append("removeImagesUrls", emailEditorRemovedImages);

        const token = localStorage.getItem('token');
        if (!token) {
            toast.error("Session out, please refresh the page and log in again");
            return;
        }

        // Dispatch the async action to create the email
        dispatch(createEmailAsync({ formData })).then(({ payload }) => {
            if (payload.status === 200) {
                dispatch(getEmailsAsync({}));
                resetEveryThing();
                onClose({ isCreated: true });
            }
        });
    };

    return (
        <>
            {isOpen && (
                <Overlay>
                    <div className={styles.composeModalContainer}>
                        <div className={styles.modalHeader}>
                            <h2 className={styles.title}>{constants?.[language].labels?.new_email}</h2>
                            <IconButton rounded onClick={handleClose} width={5} icon={<img src={close_ic} className={styles.close} />} />
                        </div>
                        <div className={styles.modalBody}>
                            <WriteEmailScreen
                                emailSubject={emailSubject}
                                setEmailSubject={setEmailSubject}
                                emailBody={emailBody}
                                setEmailBody={setEmailBody}
                                selectedParticipants={selectedParticipants}
                                setSelectedParticipants={setSelectedParticipants}
                                selectedFiles={selectedFiles}
                                setSelectedFiles={setSelectedFiles}
                            />

                        </div>
                        <div className={styles.modalFooter}>
                            <div className={styles.error}>
                                {/* Display errors */}
                                {createEmailErrors?.subject && <p className={styles.error}>{constants[language].errors.email_subject_error}</p>}
                                {createEmailErrors?.body && <p className={styles.error}>{constants[language].errors.email_body_error}</p>}
                                {createEmailErrors?.participants && <p className={styles.error}>{constants[language].errors.participants_error}</p>}

                            </div>
                            <LoadingButton width={15} isLoading={isEmailCreationLoading} onClick={handleCreateEmail}>
                                {constants?.[language].labels?.send}
                            </LoadingButton>
                        </div>
                    </div>
                </Overlay>
            )}
        </>
    );
}



