import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
export const updateTab = createAsyncThunk(
    'ui/updateTab',
    async ({ tab = "" }, thunkApi) => {
        thunkApi.dispatch(setTab(tab?.toLowerCase()));
        return tab;
    }
);
export const updateShowAds = createAsyncThunk(
    'ui/updateShowAds',
    async ({ showAds }, thunkApi) => {
        thunkApi.dispatch(setShowAds(showAds));
        return showAds;
    }
);
export const updateUploadingSongs = createAsyncThunk(
    'ui/updateUploadingSongs',
    async ({ uploadingSongs }, thunkApi) => {
        thunkApi.dispatch(setUploadingSongs(uploadingSongs));
        return uploadingSongs;
    }
)
export const updateUploadedSongs = createAsyncThunk(
    'ui/updateUploadedSongs',
    async ({ uploadedSongs }, thunkApi) => {
        thunkApi.dispatch(setUploadedSongs(uploadedSongs));
        return uploadedSongs;
    }
)
export const updateIsLoaderOpen = createAsyncThunk(
    'ui/updateIsLoaderOpen',
    async ({ isLoaderOpen }, thunkApi) => {
        thunkApi.dispatch(setIsLoaderOpen(isLoaderOpen));
        return isLoaderOpen;
    }
)
export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        tab: 'Home',
        showAds: sessionStorage.getItem('showAds') === 'false' ? false : true,
        isLoaderOpen: false
    },
    reducers: {
        setTab: (state, action) => {
            state.tab = action.payload;
        },
        setShowAds: (state, action) => {
            state.showAds = action.payload;
            sessionStorage.setItem('showAds', action.payload);
        },
        setUploadingSongs: (state, action) => {
            state.uploadingSongs = action.payload;
        },
        setUploadedSongs: (state, action) => {
            state.uploadedSongs = action.payload;
        },
        setIsLoaderOpen: (state, action) => {
            state.isLoaderOpen = action.payload

        }
    }
});
export const { setTab, setShowAds, setUploadingSongs, setUploadedSongs, setIsLoaderOpen } = uiSlice.actions;
export const selectTab = state => state.ui.tab;
export const selectShowAds = state => state.ui.showAds;
export const selectIsLoaderOpen = state => state.ui.isLoaderOpen
export default uiSlice.reducer;