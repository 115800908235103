import React from 'react';
import styles from '../../../styles/component-styles/general/checkbox.module.scss'

const Checkbox = ({ label, isChecked, name, onChange }) => {

    return (
        <div className={styles.checkbox}>
            <label>
                <input
                    name={name}
                    type="checkbox"
                    checked={isChecked}
                    onChange={onChange}
                />
                {label}
            </label>
        </div>
    );
};

export default Checkbox;
