import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGET } from "../../../apis/service";

export const getVideosReports = createAsyncThunk(
    "reports/getVideosReports",
    async ({ start,limit,sortBy, append }, thunkAPI) => {
        if(!append)
            thunkAPI.dispatch(getVideosReportsStart());
        const response = await apiGET("videos-reports", { start,limit,sortBy });
        if(response.success){
            if(append)
                thunkAPI.dispatch(appendResult(response))
            else
                thunkAPI.dispatch(getVideosReportsSuccess(response))
        }
        return response;
    }
)

export const getVideoReports = createAsyncThunk(
    "reports/getVideoReports",
    async ({ sortBy, start, limit, action, videoName, append=false }, thunkAPI) => {
        if(!append)
            thunkAPI.dispatch(getVideoReportsStart());
        const response = await apiGET(`video-reports`, { sortBy, start, limit, action, videoName });
        if (response.success)
            if (append)
                thunkAPI.dispatch(appendVideoReportResult(response))
            else
                thunkAPI.dispatch(getVideoReportsSuccess(response))
        return response;
    }
)
const reportsSlice = createSlice({
    name: "reports",
    initialState: {
        reports: null,
        totalPages: 0,
        videoReport: null,
        videoReportTotalPages: 0
    },
    reducers: {
        appendResult: (state, action) => {
            if(!state.reports){
                state.reports = action.payload.reports
            }
            else{
                let count = 0
                for(let i = 0; i < action.payload.reports.length; i++){
                    const report = action.payload.reports[i]
                    if(state.reports.find(r => r._id === report._id)){
                        count = count + 1
                    }
                }
                if(count < action.payload.reports.length)
                    state.reports = [...state.reports, ...action.payload.reports]
            }
            state.totalPages = action.payload.totalPages
        },
        getVideosReportsStart: (state) => {
            state.reports = null
            state.totalPages = 0
        },
        getVideosReportsSuccess: (state, action) => {
            state.reports = action.payload.reports
            state.totalPages = action.payload.totalPages
        },
        appendVideoReportResult: (state, action) => {
            if (!state.videoReport) {
                state.videoReport = action.payload.report
            }
            else {
                let count = 0
                for (let i = 0; i < action.payload.report.length; i++) {
                    const report = action.payload.report[i]
                    if (state.videoReport.find(r => r._id === report._id)) {
                        count = count + 1
                    }
                }
                if (count < action.payload.report.length)
                    state.videoReport = [...state.videoReport, ...action.payload.report]
            }
            state.videoReportTotalPages = action.payload.totalPages
        },
        getVideoReportsStart: (state) => {
            state.videoReport = null
            state.videoReportTotalPages = 0
        },
        getVideoReportsSuccess: (state, action) => {
            state.videoReport = action.payload.report
            state.videoReportTotalPages = action.payload.totalPages
        }
    }
})
const { appendResult, getVideosReportsStart, getVideosReportsSuccess, appendVideoReportResult, getVideoReportsStart, getVideoReportsSuccess } = reportsSlice.actions
export const selectReports = state => state.reports.reports
export const selectTotalPages = state => state.reports.totalPages
export const selectVideoReport = state => state.reports.videoReport
export const selectVideoReportTotalPages = state => state.reports.videoReportTotalPages
export default reportsSlice.reducer

