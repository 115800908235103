import { TextField } from "../../../general/input/textfield"
import UserSearchInput from "./UserSearchInput"
import styles from '../../../../styles/component-styles/admin/compose-email-modal.module.scss';
import EmailEditor from "../../../general/editors/EmailEditor";
import { resetEmailErrors } from "../../../../redux/slice/email/email-slice";
import { useDispatch, useSelector } from "react-redux";
import EmailAttachmentBox from "./EmailAttachmentBox";
import { selectLanguage } from "../../../../redux/slice/language/laguage.slice";
import { constants } from "../../../../constants";

const WriteEmailScreen = ({
    emailSubject = "", setEmailSubject = () => { },
    emailBody = "", setEmailBody = () => { },
    selectedParticipants = [], setSelectedParticipants = () => { },
    selectedFiles = [], setSelectedFiles = () => { },
}) => {
    const language = useSelector(selectLanguage)
    const dispatch = useDispatch();
    return (
        <>
            <UserSearchInput
                selectedParticipants={selectedParticipants}
                setSelectedParticipants={setSelectedParticipants}
            />

            <TextField
                name="emial-subject"
                placeholder={constants[language]?.placeholders.email_subject}
                required
                type="text"
                value={emailSubject}
                onChange={(event) => setEmailSubject(event.target.value)}
                onFocus={() => dispatch(resetEmailErrors())}
            />


            <div className={styles.editorContainer}>
                <EmailEditor
                    editorData={emailBody}
                    setEditorData={setEmailBody}
                    onFocus={() => {
                        dispatch(resetEmailErrors())
                    }} />
            </div>
            <EmailAttachmentBox
                files={selectedFiles}
                setFiles={setSelectedFiles}
            />
        </>
    )
}

export default WriteEmailScreen