import { Link, useNavigate } from "react-router-dom";
import styles from "../../../styles/component-styles/admin/layout.module.scss";
import home_ic from "../../../assets/icons/home.svg";
import song_ic from "../../../assets/icons/song.svg";
import video_ic from "../../../assets/icons/video.svg";
import playlist_ic from "../../../assets/icons/playlist.svg";
import paytip_ic from "../../../assets/icons/paytip.svg";
import logout_ic from "../../../assets/icons/logout.svg";
import reports_ic from "../../../assets/icons/reports.svg";
import email_ic from "../../../assets/icons/email.svg";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTab } from "../../../redux/slice/ui/ui-slice";
import upload_ic from "../../../assets/icons/upload.svg";
import ads_ic from "../../../assets/icons/ads.svg";
import { logoutUser } from "../../../redux/slice/auth/auth-slice";
import { constants } from "../../../constants";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import close_ic from "../../../assets/icons/close-ic.svg";
import logo from "../../../assets/icons/logo-white.svg";
import legal_ic from "../../../assets/icons/legal.svg";

// place the newly added titles in lowercase
const sidebarItems = [
  {
    title: 'upload music',
    icon:  upload_ic,
    path: '/admin',
    external: false,
  },
  {
    title: 'upload video',
    icon: upload_ic,
    external: true,
    link: 'https://www.strato.de/apps/CustomerService',
  },
  {
    title: 'songs',
    icon: song_ic,
    path: '/admin/songs',
    external: false,
  },
  {
    title: 'new songs',
    icon: song_ic,
    path: '/admin/new-songs',
    external: false,
  },
  {
    title: 'playlist',
    icon: playlist_ic,
    path: '/admin/playlist',
    external: false,
  },
  {
    title: 'videos',
    icon: video_ic,
    path: '/admin/videos',
    external: false,
  },
  {
    title: 'ads',
    icon: ads_ic,
    path: '/admin/ads',
    external: false,
  },
  {
    title: 'legal notice',
    icon: legal_ic,
    path: '/admin/legal-notice',
    external: false,
  },
  {
    title: 'reports',
    icon: reports_ic,
    path: '/admin/reports',
    external: false,
  },
  {
    title: 'users',
    icon: reports_ic,
    path: '/admin/users',
    external: false,
  },
  {
    title: 'emails',
    icon: email_ic,
    path: '/admin/emails',
    external: false,
  },
];


export function Sidebar() {
  const active = useSelector(selectTab);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);

  const logout = () => {
    dispatch(logoutUser({}));
    navigate('/');
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.top}>
        <div className={styles.logo}>
          <h1 onClick={() => navigate('/admin')}>
            <img src={logo} alt="Logo" />
          </h1>
        </div>
        <hr />
        <ul className={styles.menu}>
          {sidebarItems.map((item) => (
            <li
            key={item.title}
            className={`${styles.menuItem} ${active?.toLowerCase()===(item.title).toLowerCase() ? styles.active : ''}`}
            onClick={() => {
              if (item.external) {
                window.open(item.link, '_blank');
              } else {
                navigate(item.path);
              }
            }}
            >
              <img src={item.icon} alt={`${item.title} icon`} />
              <Link>{constants[language][item.title]}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.bottom}>
        <div onClick={logout}>
          <img src={logout_ic} alt="Logout" />
          <span className={styles.logout}>{constants[language].logout}</span>
        </div>
      </div>
    </div>
  );
}

export function MobileSidebar({ isOpen, setOpen }) {
  const active = useSelector(selectTab);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const ref = useRef(null);

  useEffect(() => {
    if (isOpen) {
      ref.current.style.left = '0';
    } else {
      ref.current.style.left = '-100%';
    }
  }, [isOpen]);

  const logout = () => {
    dispatch(logoutUser({}));
    navigate('/');
    setOpen(false);
  };

  return (
    <div className={styles.mobileSidebar} ref={ref}>
      <div className={styles.top}>
        <div className={styles.logo}>
          <h1 onClick={() => {
            navigate('/admin');
            setOpen(false);
          }}>
            {constants[language].powered_by}
          </h1>
          <span onClick={() => setOpen(false)}>
            <img src={close_ic} alt="Close" />
          </span>
        </div>
        <hr />
        <ul className={styles.menu} onClick={() => setOpen(false)}>
          {sidebarItems.map((item) => (
            <li
              key={item.title}
              className={`${styles.menuItem} ${active?.toLowerCase()===(item.title)?.toLowerCase() ? styles.active : ''}`}
              onClick={() => {
                if (item.external) {
                  window.open(item.link, '_blank');
                } else {
                  navigate(item.path);
                }
                setOpen(false);
              }}
            >
              <img src={item.icon} alt={`${item.title} icon`} />
              <Link>{constants[language][item.title]}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.bottom}>
        <div onClick={logout}>
          <img src={logout_ic} alt="Logout" />
          <span className={styles.logout}>{constants[language].logout}</span>
        </div>
      </div>
    </div>
  );
}

