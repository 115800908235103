import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Papa from 'papaparse';
import export_ic from '../../../assets/icons/export.svg';
import styles from '../../../styles/page-styles/admin/users.module.scss';
import { updateTab } from '../../../redux/slice/ui/ui-slice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers, getUsersAsync, selectUsersData } from '../../../redux/slice/user/user-slice';
import { SearchBox } from '../../../components/general/input/search-box';
import { selectLanguage } from '../../../redux/slice/language/laguage.slice';
import { UsersTable } from '../../../components/general/table/users-table';
import { constants } from '../../../constants';
import { LoadingButton } from '../../../components/general/button/LoadingButton';
import { toast } from 'react-toastify';

function Users() {
    const dispatch = useDispatch();
    const usersData = useSelector(selectUsersData);
    const language = useSelector(selectLanguage);

    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get('page')) || 1);
    const [sortBy, setSortBy] = useState(searchParams.get('sortBy') || '');
    const [search, setSearch] = useState(searchParams.get('search') || '');
    const [allUsers, setAllUsers] = useState(null);
    const [isExportLoading, setIsExportLoading] = useState(false);

    // Function to fetch users based on current search, sort, and pagination
    const fetchUsers = ({ page = 1, search = '', sortBy = '' } = {}) => {
        dispatch(getUsersAsync({ page, search, sortBy }));
    };

    // Sync the URL parameters with the current state
    const updateUrlParams = ({ page, search = "", sortBy }) => {
        const params = {};
        if (page) {
            params.page = page;
            setCurrentPage(page);
            if (page == 1) setAllUsers(null);
        }
        if (search?.trim()?.length > 0) {
            params.search = search;
            setSearch(search)
        } else {
            setSearch(search)
        }
        if (sortBy) { params.sortBy = sortBy; setSortBy(sortBy) }
        setSearchParams(params);
        fetchUsers({ ...params });
    };

    // Fetch users when the component mounts or the search parameters change
    useEffect(() => {
        dispatch(updateTab({ tab: 'Users' }));
        fetchUsers({ page: currentPage, search, sortBy });
    }, []);

    // Update the user list when the `usersData` changes
    useEffect(() => {
        if (usersData?.users) {
            const newPage = usersData.pagination?.page ?? 1;
            if (newPage === 1) {
                setAllUsers(usersData.users);
            } else {
                // setAllUsers((prevUsers) => [...prevUsers, ...usersData.users]);
                setAllUsers((prevUsers) => (prevUsers ? [...prevUsers, ...usersData.users] : [...usersData.users]));
            }
        }
    }, [usersData]);

    const onSearch = (value) => {
        const obj = { page: 1, search: value, sortBy }
        updateUrlParams({ ...obj });
    };

    const onSearchClear = () => {
        searchParams.delete('search');
        const obj = { page: 1, sortBy, search: "" }
        updateUrlParams({ ...obj });
    };

    const onSort = (field) => {
        const obj = { page: 1, search, sortBy: field }
        updateUrlParams({ ...obj });
    };

    const onExport = async () => {
        setIsExportLoading(true);
        const response = await getAllUsers({ all: true });
        if (!response?.users) {
            toast.error('Error in exporting users');
            setIsExportLoading(false);
            return;
        }

        const csvData = response.users.map(({ _id, name, email, role, createdAt }) => ({
            UserID: _id,
            Name: name,
            Email: email,
            Role: role,
            CreatedAt: new Date(createdAt).toLocaleString(),
        }));

        const csv = Papa.unparse(csvData, { header: true });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'users_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsExportLoading(false);
        toast.success(`Total of ${csvData.length} records exported successfully`);
    };

    const onLoadMore = () => {
        let page = usersData?.pagination.page
        if (page < usersData?.pagination?.totalPages) {

            const obj = { page: page + 1, search, sortBy }
            setCurrentPage(page + 1)
            updateUrlParams({ ...obj });
        }
    }
    return (
        <div className={styles.users}>
            <div className={styles.container}>
                <SearchBox
                    placeholder={constants[language].placeholders.search_placeholder_users}
                    onSearch={onSearch}
                    onClear={onSearchClear}
                    value={searchParams.get('search') || ''}
                />
                <div className={styles.btnContainer}>
                    <LoadingButton onClick={onExport} isLoading={isExportLoading}>
                        <img src={export_ic} alt="export" /> {constants[language].labels.export_to_csv}
                    </LoadingButton>
                </div>
            </div>
            <div className={styles.table}>
                <UsersTable
                    users={allUsers}
                    isSortable={true}
                    sortBy={sortBy}
                    onSort={onSort}
                    onLoadMore={onLoadMore}
                />
            </div>
        </div>
    );
}

export default Users;
