import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateTab } from "../../../redux/slice/ui/ui-slice";
import { Grid } from "../../../components/general/grid";
import styles from "../../../styles/page-styles/customer/videos.module.scss";
import { SearchBox } from "../../../components/general/input/search-box";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { constants } from "../../../constants";
import { useSelector } from "react-redux";
import {
  getVideosAsync,
  selectTotal,
  selectVideos,
} from "../../../redux/slice/video/video-slice";
import { Pagination } from "../../../components/general/pagination";
import Selector from "../../../components/general/input/selector";
import { SearchBoxIndependent } from "../../../components/general/input/search-boxIndependent";
export function Videos() {
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const totalPages = useSelector(selectTotal);
  const videos = useSelector(selectVideos);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("createdAtDesc");
  const LIMIT = 9;
  useEffect(() => {
    dispatch(updateTab({ tab: "Videos" }));
    if (!videos) {
      dispatch(
        getVideosAsync({
          query: "",
          start: (currentPage - 1) * LIMIT,
          limit: LIMIT,
          sortBy: sortBy,
        })
      );
    }
  }, []);
  useEffect(() => {
    dispatch(
      getVideosAsync({
        query: "",
        start: (currentPage - 1) * LIMIT,
        limit: LIMIT,
        sortBy: sortBy,
        append: true,
      })
    );
  }, [currentPage]);
  useEffect(() => {
    setCurrentPage(1);
    dispatch(
      getVideosAsync({
        query: "",
        start: 0 * LIMIT,
        limit: LIMIT,
        sortBy: sortBy,
      })
    );
  }, [sortBy]);
  const onSearch = (query) => {
    setCurrentPage(1);
    dispatch(
      getVideosAsync({ query, start: 0 * LIMIT, limit: LIMIT, sortBy: sortBy })
    );
  };
  const onClear = () => {
    setCurrentPage(1);
    dispatch(
      getVideosAsync({
        query: "",
        start: 0 * LIMIT,
        limit: LIMIT,
        sortBy: sortBy,
      })
    );
  };
  const options = [
    { value: "createdAtDesc", label: constants[language].newestToOldest },
    { value: "createdAtAsc", label: constants[language].oldestToNewest },
    { value: "titleAsc", label: constants[language].atoZ },
    { value: "titleDesc", label: constants[language].ztoA },
  ];
  const [value, setValue] = React.useState("");
  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className={styles.videos}>
      <SearchBoxIndependent
        placeholder={constants[language].placeholders.search_placeholder_video}
        onSearch={onSearch}
        value={value}
        onChange={onChange}
        setValue={setValue}
        onClear={onClear}
      />
      <div className={styles.sortFilter}>
        <Selector options={options} value={sortBy} onChange={setSortBy} />
      </div>
      <Grid
        videos={videos}
        searchTerm={value}
        isAdmin={false}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      {/* <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={setCurrentPage} max={10}/> */}
    </div>
  );
}
