import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateTab } from '../../../redux/slice/ui/ui-slice';
import { EmailsTable } from '../../../components/general/table/email-table';
import styles from "../../../styles/page-styles/admin/emails.module.scss";
import { LoadingButton } from '../../../components/general/button/LoadingButton';
import edit_ic from '../../../assets/icons/edit-pen-white.svg'
import { EmailCompose } from '../../../components/admin/modals/compose-email-modal';
import { getEmailsAsync, setEmailData, selectEmailsData, deleteEmailAsync } from '../../../redux/slice/email/email-slice';
import { useNavigate } from 'react-router-dom';
import { ViewEmailModal } from '../../../components/admin/modals/view-email-modal';
import { constants } from '../../../constants';
import { selectLanguage } from '../../../redux/slice/language/laguage.slice';

function Emails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isEmailComposing, setIsEmailComposing] = useState(false)
    const [isEmailViewing, setIsEmailViewing] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [allemails, setAllEmails] = useState(null)
    const emailsData = useSelector(selectEmailsData)
    const language = useSelector(selectLanguage)
    useEffect(() => {
        dispatch(updateTab({ tab: 'emails' }))
        if (currentPage < 1) {
            dispatch(getEmailsAsync({ page: 1 }))
        }
    }, [])
    useEffect(() => {
        if (emailsData?.emails) {
            setCurrentPage(emailsData?.pagination?.page ?? 1)
            if (emailsData?.pagination?.page === 1)
                setAllEmails(emailsData.emails)
            else
                setAllEmails((prevEmails) => ([...prevEmails, ...emailsData.emails]))

        }
    }, [emailsData])
    useEffect(() => {
        if (currentPage > 1) {
            dispatch(getEmailsAsync({ page: currentPage }))
        }

    }, [currentPage])
    const handleComposeEmail = () => {
        setIsEmailComposing(true)
    }

    const handleRemoveEmail = (email) => {
        // Implement email removal logic here
        dispatch(deleteEmailAsync({ id: email?._id })).then((res) => {
            if (res?.payload?.success) {
                dispatch(getEmailsAsync({ page: 1 }))
                setCurrentPage(1)
                setEmailData(null)
            }

        })
    }

    return (
        <div className={styles.emails}>
            <div className={styles.pageHeader}>
                <div></div>
                <div>
                    <LoadingButton onClick={handleComposeEmail} icon={<img src={edit_ic} width={24} alt={"edit"} />}>{constants[language].labels.compose_email}</LoadingButton>
                </div>
            </div>
            <EmailsTable
                data={allemails}
                onView={(email) => {
                    setIsEmailViewing(true)
                    dispatch(setEmailData(email))
                }}
                onRemove={handleRemoveEmail}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalPages={emailsData?.pagination?.totalPages}
            />

            <EmailCompose isOpen={isEmailComposing} onClose={({ isCreated }) => {
                if (isCreated) {
                    setAllEmails(null)
                }
                setIsEmailComposing(false)
            }} />
            <ViewEmailModal isOpen={isEmailViewing} onClose={() => setIsEmailViewing(false)} />
        </div>
    )
}

export default Emails