export const english = {
    songs: "Songs",
    playlist: "My Songlist",
    videos: "Videos",
    search: "Search",
    clear: "Clear",
    ads: "Ads",
    logout: "Logout",
    "Top Searched songs": "Top Searched songs",
    "new songs": "New Songs",
    "upload music": "Upload Music",
    "Playlist": "My Songlist",
    "Ads": "Ads",
    "upload video": "Upload Video",
    "videos": "Videos",
    start_upload: "Start by uploading a file",
    upload_description: "Any assets used in projects will live here. Start creating by uploading your files.",
    upload_btn: "Upload",
    no: "No.",
    title: "Title",
    artist: "Artist",
    language: "Language",
    action: "Action",
    add_to_playlist: "Add To My Songlist",
    remove_from_playlist: "Remove From My Songlist",
    sr: "Sr.",
    no_data: "No Data Found",
    created_on: "Created On",
    expired_on: "Expired On",
    status: "Status",
    loading: "Loading Data ...",
    actions: "Actions",
    create_ad: "Create an Ad",
    no_data_search: "No songs were found. Type song title AND artist name in search",
    create_ad_description: "Add details to publish your ad.",
    click_upload: "Click to upload",
    drag_drop: "or drag and drop",
    file_type: "SVG, PNG, JPG or GIF (max. 800x400px)",
    ad_title: "Ad Title",
    description: "Description",
    ad_expiry: "Ad Expiry",
    characters: "Characters",
    cancel: "Cancel",
    publish: "Publish",
    ad_description: "Ad Description",
    active: "Active",
    close: "Closed",

    // customer
    home: "Home",
    paytip: "Pay Tip",
    "New Releases": "New Releases",
    recent_added: "Recently Added",
    close_ad: "Close Ad",
    "Home": "Home",
    "Search Songs": "Search Songs",
    "Search Playlist": "Search Playlist",
    "Paytip": "Pay Tip",
    "Contact Us": "Contact Us",
    "legal notice": "Legal Notice",
    leave_a_tip: "Leave a tip via PayPal",
    leave_a_tip_description: "Support our app by leaving a voluntary tip via PayPal",
    pay_btn: "Pay with Stripe",
    powered_by: "powered by felix",
    upload_new_btn: "Upload New",
    thumbnail: "Thumbnail",
    payment_required: "To Watch full video, you need to make a payment.",
    contact: "Contact Us",
    newestToOldest: "Newest To Oldest",
    oldestToNewest: "Oldest To Newest",
    atoZ: "A to Z",
    ztoA: "Z to A",
    sort_by: "Sort By",
    responsible_for: "Responsible For this page",
    desclaimer: "Disclaimer",
    desclaimer_desc: "Despite careful content control, we assume no liability for the content of external links. The content of linked pages is the sole responsibility of their operators.",
    privacy_policy: "Privacy Policy",
    privacy_policy_desc: "We take the protection of your personal data very seriously and strictly adhere to all applicable laws and regulations on data protection, in particular the General Data Protection Regulation (GDPR), the Federal Data Protection Act (BDSG), and the Telemedia Act (TMG). The following explanations provide an overview of how we ensure this protection and what data we process for what purposes.",
    usage_data: "Usage Data",
    usage_data_descriptions: [
        "Each time the website is accessed and each time a file is retrieved, general data about this process is automatically stored in a log file. The storage serves exclusively system-related and statistical purposes (based on Art. 6(1)(b) GDPR) as well as in exceptional cases to report criminal offenses (based on Art. 6(1)(e) GDPR).",
        "No transfer of data to third parties or other evaluations take place, unless there is a legal obligation to do so (Art. 6(1)(e) GDPR)."],
    usage_data_bullets_heading: "Specifically, the following data set is stored for each retrieval",
    usage_data_bullets: [
        "Name of the retrieved file",
        "Date and time of the retrieval",
        "Amount of data transferred",
        "Notification whether the retrieval was successful",
        "Description of the type of web browser used",
        "Operating system used",
        "The previously visited page",
        "Provider",
        "Your IP address"
    ],
    personal_data: "Personal Data",
    personal_data_desc: "Personal data is processed only if legally permitted or if you have given your consent.",
    specifically: "Specifically",
    personal_data_bullets: [
        {
            heading: "Contact",
            descriptions: [
                "When you contact us, we store your data based on Art. 6(1)(b) GDPR for the purpose of processing your request, as well as in case further correspondence should take place."
            ]
        },
        {
            heading: "Comment Function",
            descriptions: [
                "You have the opportunity to leave individual comments on specific blog or guestbook posts published on our website.",
                "If you leave such a comment, in addition to the content of the comment itself, the time of the comment's entry is also stored and published.",
                "Furthermore, your IP address will also be recorded. This is done for proof and documentation purposes in the event that we are held liable for any legal violations due to your comment. Therefore, data processing is necessary to safeguard our legitimate interests pursuant to Art. 6(1)(f) GDPR.",
                "Your personal data will only be passed on to third parties if we are legally obliged to do so (then on the basis of Art. 6(1)(e) GDPR), or if this is necessary to protect the legitimate interests of the third party (then on the basis of Art. 6(1)(f) GDPR)."

            ]
        }
    ],
    cookies: "Cookies",
    cookies_descriptions: [
        "We use so-called cookies in some areas of our website. These file elements can identify your computer as a technical unit during your visit to this website to make the use of our offerings easier for you on repeat visits.",
        "However, you usually have the option to set your internet browser to inform you of the occurrence of cookies, so that you can allow or exclude them, or delete already existing cookies.",
        "Please use the help function of your internet browser to obtain information on changing these settings. Please note that some functions of our website may not work if you have disabled the use of cookies.",
        "Cookies do not allow a server to read private data from your computer or data stored by another server. They do not cause any damage to your computer and do not contain any viruses.",
        "We base the use of cookies on Art. 6(1)(f) GDPR: processing is carried out to improve the functionality of our website. It is therefore necessary to safeguard our legitimate interests."
    ],
    usage_social_media: "Use of Social Plugins from Facebook, Twitter, Google+, and Instagram",
    usage_social_media_descriptions: [
        "Protecting your personal data is very important to us. Therefore, we do not directly implement the various social plugins.",
        "On our pages, we only set links to these social networks. Data will only be transferred if you follow the links and the corresponding social plugins are activated.",
        "Please note that data is only transferred when you activate the corresponding social plugins by clicking on the corresponding link. Simply visiting our pages does not result in any data transmission."
    ],
    usage_social_media_bullets_heading: "If you activate data transmission by clicking the corresponding link, the following data transfer will occur",
    usage_social_media_bullets: [
        {
            heading: "Facebook",
            descriptions: [
                `Plugins of the social network Facebook (Facebook Inc., 1601 Willow Road, Menlo Park, CA 94025, USA) are then integrated on our site. You can recognize the Facebook plugins by the Facebook logo or the "Like Button" ("Like") on our site. An overview of Facebook plugins can be found here: http://developers.facebook.com/docs/plugins/.`,
                `A direct connection is established between your browser and the Facebook server via the plugin. Facebook thereby receives the information that you have visited our site with your IP address. If you click the Facebook "Like Button" while you are logged into your Facebook account, you can link the content of our site to your Facebook profile. This allows Facebook to associate the visit to our site with your account.`,
                `We point out that we as the site operators do not have any knowledge of the content of the transmitted data or their use by Facebook. Further information can be found in Facebook's privacy policy at http://de-de.facebook.com/policy.php.`,
                `If you do not want Facebook to associate your visit to our site with your Facebook account, please log out of your Facebook account or do not activate the social plugins.`
            ]
        },
        {
            heading: "Google+1",
            descriptions: [
                "Functions of the Google+ service are integrated into our site. These functions are provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.",
                "By using Google+ and the Google button, the browser establishes a direct connection with Google's servers. The content of the Google button is transmitted directly from Google to its browser and integrated into the website.",
                "We have no influence on the amount of data Google collects with the button.",
                "For information on the purpose and scope of data collection and the further processing and use of the data by Google, as well as your rights and settings options to protect your privacy, please refer to Google's privacy notice on the Google button: http://www.google.com/intl/de/+/policy/+1button.html and the FAQ: http://www.google.com/intl/de/+1/button/."
            ]
        },
    ],
    use_pulgin_youtube: "Use of Social Plugins from YouTube",
    use_pulgin_youtube_descriptions: [
        "Our website also links to the YouTube social network. We also operate a social media page here.",
        "If you click on a link to YouTube, you will be redirected to the respective external page on YouTube. If you are also logged in as a member of YouTube, the operator, YouTube LLC, 901 Cherry Ave., San Bruno, CA 94066, USA, can assign your visit to our page to your respective user account. We point out that YouTube LLC is part of the Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.",
        "If you do not want YouTube to collect and store data about your visit to our website, you must log out of your YouTube account before clicking the link.",
        "For information on the purpose and scope of data collection and further processing and use of your data by YouTube, please refer to the corresponding privacy policy, which you can find here: http://www.google.de/intl/de/policies/privacy/.",
        "We base the use of YouTube on Art. 6(1)(a) GDPR. By clicking the link while simultaneously logged in to YouTube, you give us your consent to data processing. In any case, data processing is permissible pursuant to Art. 6(1)(f) GDPR; by clicking the link while logged in to the respective social network, data processing is carried out for advertising purposes and is therefore in our legitimate interest."
    ],
    usage_google_utility: "Use of Google Web Fonts, Google Maps, and OpenStreetMap",
    usage_google_utility_bullets: [
        {
            heading: "Use of Google Web Fonts and Google Maps",
            descriptions: [
                "Our website uses external fonts, so-called Google Web Fonts. We also use Google Maps to display the directions to our company and to simplify your travel planning.",
                "We use services from Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA for this purpose.",
                "When you call up our page, your browser loads the necessary information from the Google server in the USA into its browser cache. This is necessary for your browser to display an improved visual representation of our texts, or for the map to be displayed on our website.",
                "This transmits to the Google server which of our Internet pages you have visited. Your IP address is also stored by Google.",
                "Further information about Google Web Fonts can be found at https://developers.google.com/fonts/faq?hl=de-DE&csw=1.",
                "Further information about Google Maps can be found at https://www.google.com/intl/de_de/help/terms_maps.html.",
                "General information on the subject of data protection at Google can be found at www.google.com/policies/privacy/"
            ]
        },
        {
            heading: "Use of Google Analytics",
            descriptions: [
                "This website uses Google Analytics, a web analytics service provided by Google Inc.",
                "Google Analytics uses text files stored on your computer to help analyze how you use the website.",
                "The information generated by the cookie about your use of this website is usually transmitted to and stored by Google on servers in the USA.",
                "If IP anonymization is activated on this website, your IP address will be truncated by Google within Member States of the European Union or in other contracting states to the Agreement on the European Economic Area.",
                "Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and truncated there.",
                "On behalf of the operator of this website, Google will use this information to evaluate your use of the website, compile reports on website activity, and provide other services related to website and internet use to the website operator.",
                "The IP address transmitted by your browser as part of Google Analytics will not be merged with other data from Google.",
                "You may refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this, you may not be able to use the full functionality of this website.",
                "You can also prevent the collection of data generated by the cookie about your use of the website (including your IP address) by Google and the processing of this data by Google by downloading and installing the browser plugin available at http://tools.google.com/dlpage/gaoptout?hl=de."
            ]
        }
    ],
    duration_storage: "Duration of Storage",
    duration_storage_desc: "We store your data for as long as is necessary for the final processing of your request. Excluded from this are data for which legal or otherwise prescribed retention obligations exist; these are stored for the duration of the respective retention period and then routinely deleted.",
    rights_data_subject: "Rights of the Data Subject",
    rights_data_subject_bullets: [
        {
            heading: "Right of Access",
            description: "You have the right to request confirmation from us as to whether personal data concerning you is being processed.",
            footer: "To do so, please simply send an email to bobfel (at) arcor.de"
        },
        {
            heading: "Rectification/Erasure/Restriction of Processing",
            description: "You also have the right to request from us that",
            bullets: [
                "incorrect personal data concerning you be corrected without delay (right to rectification);",
                "personal data concerning you be deleted without delay (right to erasure); and",
                "the processing be restricted (right to restriction of processing)."
            ],
            footer: "To do so, please simply send an email to bobfel (at) arcor.de"
        },
        {
            heading: "Right to Data Portability",
            description: "You have the right to receive personal data concerning you, which you have provided to us, in a structured, commonly used, and machine-readable format and to transmit those data to another controller.",
            footer: "To do so, please simply send an email to bobfel (at) arcor.de"
        },
        {
            heading: "Right to Withdraw Consent",
            description: "You have the right to withdraw your consent at any time. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal.",
            footer: "To do so, please simply send an email to bobfel (at) arcor.de"
        },
        {
            heading: "Right to Object",
            description: "Where the processing of personal data concerning you is necessary for the performance of a task carried out in the public interest (Art. 6(1)(e) GDPR) or for the purposes of legitimate interests (Art. 6(1)(f) GDPR), you have the right to object to such processing.",
            footer: "To do so, please simply send an email to bobfel (at) arcor.de"
        },
        {
            heading: "Right to lodge a complaint",
            description: "If you believe that the processing of personal data concerning you violates the GDPR, you have the right to lodge a complaint with a supervisory authority, without prejudice to any other legal remedies.",
        }
    ],
    security: "Security",
    security_desc: "We use the latest internet technologies for the security of your data. To securely store your data, our systems are protected by firewalls that prevent unauthorized access from outside.",
    video_selected: "video selected",
    "Reports": "Reports",
    video_title: "Video Title",
    downloads: "Downloads",
    views: "Views",
    uploaded_on: "Uploaded On",
    export_to_csv: "Export to CSV",
    reports: "Reports",
    emails: "Emails",
    users: "Users",
    download: "Download",
    download_by: "Downloaded By",
    download_on: "Downloaded On",
    view_by: "Viewed By",
    view_on: "Viewed On",
    // email related translations
    email: "Email",

    labels: {
        name: "Name",
        joining_date: "Joining Date",
        email: "Email",
        subject: "Subject",
        body: "Body",
        date_or_time: "Date/Time",

        recipients: "Recipients",
        attachments: "Attachments",
        send: "Send",
        cancel: "Cancel",
        clear: "Clear",
        delete: "Delete",
        search: "Search",
        select_all: "Select All",
        unselect_all: "Unselect All",
        view: "View",
        view_less: "View Less",
        view_all: "View All",
        download: "Download",
        compose_email: "Compose Email",
        load_more: "Load More",
        new_email: "New Email",
        email_preview: "Email Preview",
        no_record_found: "No Record Found",
        refech: "Refech",
        export_to_csv: "Export All To CSV",
        
    },
    placeholders: {
        email_subject: "Email Subject",
        email_content: "Email Content Here",
        recipients: "Enter Recipients",
        search_placeholder_songs: "Search artists, songs, albums...",
        search_placeholder_users: "Search by name, email",
        attachments_dragdrop_msg: "Drag and Drop Files Here or Click to Select Files",
        attachments_size_msg: "Max upload size (20 MB)",
        attachments_support_files_msg: "Supported files: .jpg, .png, .gif, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .mp3, .mp4",
        search_placeholder_video: "Search videos...",

    },
    errors: {
        email_subject_error: "Subject cannot be empty",
        email_body_error: "Email body cannot be empty",
        participants_error: "Participants cannot be empty",
    }
}