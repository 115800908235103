import styles from '../../../styles/component-styles/admin/view-email-modal.module.scss';
import { Overlay } from './overlay';
import close_ic from "../../../assets/icons/close.svg";
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage } from '../../../redux/slice/language/laguage.slice';
import { IconButton } from '../../general/button/IconButton';
import { useEffect, useState } from 'react';
import { getEmailAsync, setEmailData, selectEmailData } from '../../../redux/slice/email/email-slice';
import { BASE_URL, PAGE_LIMIT_FOR_USERS_FOR_EMAIL } from '../../../utils/constants';
import { constants } from '../../../constants';
export function ViewEmailModal({ isOpen, onClose = () => { alert("default handler") } }) {
    const dispatch = useDispatch();
    const language = useSelector(selectLanguage);

    const emailData = useSelector(selectEmailData);
    useEffect(() => {
        // Clean up the state variables
        return () => {
            dispatch(setEmailData(null))
        };
    }, []);

    const handleClose = async () => {
        onClose();
    }


    // const handleResendEmail = () => {


    //     const token = localStorage.getItem('token');
    //     if (!token) {
    //         toast.error("Session out, please refresh the page and log in again");
    //         return;
    //     }

    //     // Dispatch the async action to create the email
    //     dispatch(resendEmailAsync({ id: emailData?._id })).then(({ payload }) => {
    //         if (payload.status === 200) {
    //             dispatch(getEmailsAsync({}));
    //             handleClose();
    //         }
    //     });
    // };

    return (
        <>
            {isOpen && (
                <Overlay>
                    <div className={styles.viewEmailModalContainer}>
                        <div className={styles.modalHeader}>
                            <h2 className={styles.title}>{constants[language].labels.email_preview}</h2>
                            <IconButton rounded onClick={handleClose} width={5} icon={<img src={close_ic} className={styles.close} />} />
                        </div>
                        <div className={styles.modalBody}>
                            <EmailScreen
                                emailData={emailData}
                            />
                        </div>
                        {/* <div className={styles.modalFooter}>
                            <div className={styles.error}>
                            </div>
                            <LoadingButton>
                                Resend
                            </LoadingButton>
                        </div> */}
                    </div>
                </Overlay>
            )}
        </>
    );
}


const EmailScreen = ({ emailData = {} }) => {
    const [showLess, setShowLess] = useState(true);
    const language = useSelector(selectLanguage);

    const showData = emailData?.participants?.length > PAGE_LIMIT_FOR_USERS_FOR_EMAIL && showLess
        ? emailData?.participants.slice(0, PAGE_LIMIT_FOR_USERS_FOR_EMAIL)
        : emailData?.participants;

    return (
        <>
            <div>
                <div className={styles.label}>{constants[language].labels.subject}:</div>
                <div className={styles.box}>{emailData?.subject}</div>
            </div>
            <div>
                <div className={styles.label}>{constants[language].labels.recipients}:</div>
                <div className={styles.box}>
                    {showData?.map((participant, index) => (
                        <span className={styles.tags} key={index}>{participant.name}</span>
                    ))}
                    {emailData?.participants?.length > PAGE_LIMIT_FOR_USERS_FOR_EMAIL && (showLess ? <span className={styles.viewAllLess} onClick={() => setShowLess(false)}>{constants[language].labels.view_all}</span> : <span className={styles.viewAllLess} onClick={() => setShowLess(true)}>{constants[language].labels.view_less}</span>)}

                </div>
            </div>
            <div>
                <div className={styles.label}>{constants[language].labels.body}:</div>

                <div className={styles.box}>
                    <div className={styles.emailBody} dangerouslySetInnerHTML={{ __html: emailData?.body }} />
                </div>
            </div>
        </>
    )
}


