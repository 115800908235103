import { Button } from "./Button";

export function LoadingButton({ isLoading, children, ...props }) {
    return (
        <Button isLoading={isLoading} {...props}>
            {children}
        </Button>
    );
};

