import React, { useState } from 'react';
import DragDropArea from '../../../general/dragable-upload';
import styled from 'styled-components';
import trash_ic from '../../../../assets/icons/trash.svg';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../redux/slice/language/laguage.slice';

function EmailAttachmentBox({ files = [], setFiles = () => { } }) {

    // 20 MB size limit
    const MAX_SIZE = 20 * 1024 * 1024;

    // Allowed file types for attachments
    const allowedFileTypes = [
        'image/jpeg', 'image/png', 'image/gif',
        'application/pdf',
        'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOC & DOCX
        'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',  // XLS & XLSX
        'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPT & PPTX
        'text/plain',
        'audio/mpeg', 'video/mp4',
    ];


    const calculateTotalSize = (fileList) => {
        return fileList.reduce((total, file) => total + file.size, 0);
    };

    const handleFileUpload = (uploadedFiles) => {
        const newFiles = Array.from(uploadedFiles);

        const totalSize = calculateTotalSize([...files, ...newFiles]);

        // Check if all files are of allowed types
        const validFiles = newFiles.filter((file) => allowedFileTypes.includes(file.type));
        const invalidFiles = newFiles.filter((file) => !allowedFileTypes.includes(file.type));

        if (invalidFiles.length > 0) {
            // Extract the file extensions and show them in a toast with new lines
            const invalidExtensions = invalidFiles.map(file => {
                const extension = file.name.split('.').pop();
                return extension ? `.${extension}` : 'unknown';
            });

            // Use \n for new lines, and for react-toastify, allow HTML for multi-line support
            toast.warning(
                <>
                    Some files were not allowed and will not be uploaded:
                    <br />
                    {invalidExtensions.map((ext, i) => (
                        <i key={i} >{ext}<br /></i>
                    ))}
                </>
            );
        }

        // Check if the total size exceeds 20 MB
        if (totalSize > MAX_SIZE) {
            toast.warning('Total file size exceeds the 20 MB limit.');
            return;
        }


        // Add files if size is within the limit
        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    };

    const handleRemoveFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    return (
        <div>
            <DragDropArea onFileUpload={handleFileUpload} />
            <FileList files={files} onRemoveFile={handleRemoveFile} />
        </div>
    );
}

export default EmailAttachmentBox;

const FileList = ({ files, onRemoveFile }) => {
    return (
        <div>
            {files.map((file, index) => (
                <StyledFileList key={index}>
                    <span className='rightSide'>{file.name}</span>
                    <div className='leftSide'>
                        <span className='fileSize'>{(file.size / 1024).toFixed(2)} KB</span> {/* Convert bytes to KB and format */}

                        <img src={trash_ic} alt="action"
                            onClick={() => onRemoveFile(index)}
                        />
                    </div>
                </StyledFileList>
            ))}
        </div>
    );


};

const StyledFileList = styled.div`
    padding: 10px;
    border: solid 1px ;
    background: transparent;
    margin-block: 10px;
    color: ${(props) => props.theme.colors.secondaryText};
    border-radius: ${(props) => (props.rounded ? '50px' : props.theme.borderRadius.md)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover{
        color: ${(props) => props.theme.colors.primary};
    }
    .rightSide{
        width: 70%;
        // width: 70%;              /* Restrict the width */
        // white-space: nowrap;      /* Prevent text from wrapping */
        // overflow: hidden;         /* Hide overflow */
        // text-overflow: ellipsis;  /* Add ellipsis (...) for overflowing text */
    }
    .leftSide{
        display: flex;
        align-items: center;
        gap: 5px;
        .fileSize{
            font-size: 12px;
        }
        img{
            cursor: pointer;
            &:hover{
                background-color: white};
                padding: 5px;
                border-radius: 50%;
                width: 17px;
            }
            
        }
    }
`;