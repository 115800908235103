import React, { useRef, useEffect } from "react";
import styles from "../../../styles/component-styles/admin/modals.module.scss";
import { Overlay } from "./overlay";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { updateVideoCountAsync } from "../../../redux/slice/video/video-slice";
import { useDispatch } from "react-redux";

export function VideoPlayer({ isAdmin, videoName, videoUrl, isOpen, onClose }) {
  const downloadLinkRef = useRef(null);
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const dispatch = useDispatch();

  const allowedStart = 60;
  const allowedEnd = 120;

  useEffect(() => {
    if (videoName)
      dispatch(updateVideoCountAsync({ videoName, action: "view" })).then(res => console.log("Video download count res: ", res));

  }, [videoName])

  useEffect(() => {
    if (!isAdmin && videoRef.current) {
      // Set the video to start at 50 seconds for non-admin users
      videoRef.current.currentTime = allowedStart;
    }
  }, [isAdmin, isOpen]);

  const handleDownload = () => {
    if (downloadLinkRef.current) {
      // downloadLinkRef.current.click();
      dispatch(updateVideoCountAsync({ videoName, action: "download" })).then(res => console.log("Video download count res: ", res));
    }
  };
  // Handle video preview limitation for non-admins
  const handleTimeUpdate = () => {
    if (!isAdmin && videoRef.current) {
      if (videoRef.current.currentTime >= allowedEnd) {
        videoRef.current.pause();
        toast.info(
          "Video preview has ended. Please contact Felix Entertainment to purchase the full video."
        );
      }
    }
  };

  // Prevent fast-forwarding and rewinding for non-admin users
  const handleSeek = () => {
    if (!isAdmin && videoRef.current) {
      if (videoRef.current.currentTime < allowedStart) {
        videoRef.current.currentTime = allowedStart; // Reset to allowed start
      } else if (videoRef.current.currentTime > allowedEnd) {
        videoRef.current.currentTime = allowedEnd; // Reset to allowed end
      }
    }
  };

  if (!isOpen) return null;

  return (
    <>
      {isOpen && (
        <Overlay>
          <div className={styles.watchVideo}>
            {/* Close Button */}
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row-reverse",
              }}
            >
              <button style={{ textAlign: "right" }} onClick={onClose}>
                X
              </button>
            </div>

            <h2>
              {decodeURIComponent(
                videoName
                  .split("-")
                  .slice(1, 9)
                  .map((item) => item)
                  .join(" ")
              )}
            </h2>

            {/* Video player */}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div className={styles.content}>
                <video
                  controls
                  className="new_videoPlayer"
                  ref={videoRef}
                  onTimeUpdate={handleTimeUpdate}
                  onSeeking={handleSeek} // Prevent fast-forwarding/rewinding
                >
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>

            {/* Download button */}
            {isAdmin ? (
              <div className={styles.actions}>
                <button
                  disabled={!isAdmin}
                  onClick={handleDownload}
                  className={styles.downloadButton}
                >
                  Download
                </button>

                {/* Hidden download link */}
                <a
                  href={videoUrl}
                  ref={downloadLinkRef}
                  download={videoName}
                  style={{ display: "none" }}
                >
                  Download
                </a>
              </div>
            ) : (
              <div className={styles.actions}>
                <button
                  onClick={() => {
                    navigate("/customer/contact-us");
                  }}
                >
                  Contact to Buy
                </button>
              </div>
            )}
          </div>
        </Overlay>
      )}
    </>
  );
}
