import React, { useEffect, useState } from "react";
import styles from "../../../styles/component-styles/admin/modals.module.scss";
import { Overlay } from "./overlay";
import { useDispatch, useSelector } from "react-redux";
import {
  getVideoAsync,
  onDownloadVideoAsync,
  selectVideo,
} from "../../../redux/slice/video/video-slice";
import { formatViews } from "../../../utils/formatViews";
import { formatAgo } from "../../../utils/formatAgo";
import close_ic from "../../../assets/icons/close-ic.svg";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { constants } from "../../../constants";
import { PrimaryButton } from "../../general/input/primary-button";
import stripe_ic from "../../../assets/icons/stripe.png";
import loader from "../../../assets/gifs/loader.webp";
import { loadStripe } from "@stripe/stripe-js";
import { createPaymentAsync } from "../../../redux/slice/payment/payment-slice";
import { useRef } from "react";
export function WatchVideo({ videoId, isOpen, onClose, isAdmin }) {
  const dispatch = useDispatch();
  const video = useSelector(selectVideo);
  const language = useSelector(selectLanguage);
  const [paymentNotification, setPaymentNotification] = useState(false);
  const [isUserPaid, setIsUserPaid] = useState(false);
  useEffect(() => {
    if (videoId) {
      dispatch(getVideoAsync({ id: videoId }));
    }
    setPaymentNotification(false);
    setIsUserPaid(false);
  }, [videoId]);
  useEffect(() => {
    if (video) {
      if (video.paymentStatus === "paid" || isAdmin) {
        setIsUserPaid(true);
      }
    }
  }, [video]);
  const SECONDS_LIMIT = 60;
  const linkRef = useRef(null);
  const makePayment = async () => {
    dispatch(createPaymentAsync({ videoId }));
  };
  const onDownload = (videoId) => {
    if (linkRef) {
      linkRef.current.click();
      dispatch(onDownloadVideoAsync({ videoId }));
    }
  };
  return (
    <>
      {isOpen && (
        <Overlay>
          <div className={styles.watchVideo}>
            {video && (
              <>
                <div className={styles.close}>
                  <img src={close_ic} onClick={onClose} />
                </div>

                <div className={styles.content}>
                  {!paymentNotification ? (
                    <video
                      controls
                      src={
                        !isUserPaid
                          ? `${video.videoUrl}#t=0,${SECONDS_LIMIT}`
                          : video.videoUrl
                      }
                      className={styles.video}
                      controlsList={"nodownload"}
                      onEnded={() => {
                        if (!isUserPaid) setPaymentNotification(true);
                      }}
                      onTimeUpdate={(e) => {
                        if (
                          e.target.currentTime >= SECONDS_LIMIT &&
                          !isUserPaid
                        ) {
                          setPaymentNotification(true);
                        }
                      }}
                    />
                  ) : (
                    <div className={styles.paymentNotification}>
                      <p>{constants[language].payment_required}</p>
                      <PrimaryButton
                        style={{ width: "230px" }}
                        onClick={makePayment}
                      >
                        <img src={stripe_ic} width={20} />
                        {constants[language].pay_btn}
                      </PrimaryButton>
                    </div>
                  )}
                  <div className={styles.titleAndBtn}>
                    <h1 className={styles.title}>{video.title}</h1>
                    {isUserPaid && (
                      <>
                        <a href={video.videoUrl} ref={linkRef}></a>
                        <button
                          className={styles.downloadBtn}
                          onClick={() => onDownload(video._id)}
                        >
                          {constants[language].download}
                        </button>
                      </>
                    )}
                  </div>
                  <div className={styles.info}>
                    {/* <p className={styles.view}>{formatViews(video.view)}</p> */}
                    <p className={styles.createdAt}>
                      {formatAgo(video.createdAt)}
                    </p>
                  </div>
                  {/* <p className={styles.description}>{video.description}</p> */}
                </div>
              </>
            )}
            {!video && (
              <div className={styles.noData}>
                <img src={loader} />
                <p>{constants[language].loading}</p>
              </div>
            )}
          </div>
        </Overlay>
      )}
    </>
  );
}
