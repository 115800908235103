import { useEffect, useRef, useState } from "react";
import remove_ic from "../../../assets/icons/remove-music.svg";
import export_ic from "../../../assets/icons/export.svg";
import styles from "../../../styles/component-styles/general/table.module.scss";
import no_data from "../../../assets/images/no_data.svg"
import loader from "../../../assets/gifs/loader.webp"
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { useSelector } from "react-redux";
import { constants } from "../../../constants";
import { useInView } from "react-intersection-observer";
import { convertDatetimeFormat } from "../../../utils/convert-datetime-formet";
export function UsersTable({ users = null, onSort = () => { }, sortBy, onLoadMore = () => { } }) {
    const [active, setActive] = useState(null)
    const language = useSelector(selectLanguage)
    const [ref, inView] = useInView({ triggerOnce: false })

    useEffect(() => {
        if (inView) {
            onLoadMore()
        }
    }, [inView])

    return <table className={styles.table}>
        <thead>
            <tr>
                <th className={styles.head}>
                    {constants[language].no}</th>
                <th className={styles.head}>
                    <div className={styles.header}>
                        {constants[language]?.labels?.name}
                        <div className={styles.sorting}>
                            <button className={styles.sortingBtn + " " + (sortBy == 'nameAsc' ? styles.active : "")}
                                onClick={() => onSort('nameAsc')}
                            >
                                ▲
                            </button>
                            <button className={styles.sortingBtn + " " + (sortBy == 'nameDesc' ? styles.active : "")}
                                onClick={() => onSort('nameDesc')}>
                                ▼
                            </button>
                        </div>
                    </div>
                </th>
                <th className={styles.head}>
                    <div className={styles.header}>
                        {constants[language].labels.email}
                        <div className={styles.sorting}>
                            <button className={styles.sortingBtn + " " + (sortBy == 'emailAsc' ? styles.active : "")}
                                onClick={() => onSort('emailAsc')}>
                                ▲
                            </button>
                            <button className={styles.sortingBtn + " " + (sortBy == 'emailDesc' ? styles.active : "")}
                                onClick={() => onSort('emailDesc')}>
                                ▼
                            </button>
                        </div>
                    </div>
                </th>
                <th className={styles.head}>
                    <div className={styles.header}>
                        {constants[language]?.labels?.joining_date}
                        <div className={styles.sorting}>
                            <button className={styles.sortingBtn + " " + (sortBy == 'createdAtAsc' ? styles.active : "")}
                                onClick={() => onSort('createdAtAsc')}>
                                ▲
                            </button>
                            <button className={styles.sortingBtn + " " + (sortBy == 'createdAtDesc' ? styles.active : "")}
                                onClick={() => onSort('createdAtDesc')}>
                                ▼
                            </button>
                        </div>
                    </div>
                </th>
            </tr>
        </thead>

        <tbody>

            <tr style={{ height: "fit-content" }}>
                <td colSpan={5}>
                    <div className={styles.line}></div>
                </td>

            </tr>

            {users && users?.map((singleUser, index) => <tr key={index}
                ref={index === users?.length - 1 ? ref : null}
            >
                <td><div className={`${styles.no}  ${active == index ? styles.active : ""}`}>{index + 1}</div></td>
                <td><div className={`${styles.title} ${active == index ? styles.active : ""}`}>
                    {singleUser.name}</div></td>
                <td><div className={`${styles.artist}  ${active == index ? styles.active : ""}`}>{singleUser.email}</div></td>
                <td><div className={`${styles.genre}  ${active == index ? styles.active : ""}`}>
                    {convertDatetimeFormat(singleUser.createdAt)}
                </div></td>


            </tr>)}
            {users && users?.length == 0 && <tr><td colSpan={5}>
                <div className={styles.noData}>
                    <img src={no_data} />
                    <p>{constants[language].no_data}</p>
                </div>
            </td></tr>}
            {!users && <tr><td colSpan={5}>
                <div className={styles.noData}>
                    <img src={loader} />
                    <p>{constants[language].loading}</p>
                </div>
            </td></tr>}
        </tbody>
    </table>
}