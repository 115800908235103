import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchBox } from '../../../components/general/input/search-box';
import { List } from '../../../components/general/list';
import { Pagination } from '../../../components/general/pagination';
import { Table } from '../../../components/general/table';
import { getPlaylistSongsAsync, searchPlaylistSongsAsync, selectPlaylistSongs, selectTotalPages } from '../../../redux/slice/playlist/playlist-slice';
import { updateTab } from '../../../redux/slice/ui/ui-slice';
import styles from '../../../styles/page-styles/admin/playlist.module.scss';
import { constants } from '../../../constants';
import { selectLanguage } from '../../../redux/slice/language/laguage.slice';
export function Playlist() {
    const dispatch = useDispatch();
    const totalPage = useSelector(selectTotalPages)
    const [currentPage, setCurrentPage] = useState(1);
    const songs = useSelector(selectPlaylistSongs)
    const LIMIT = 8
    const [sortBy, setSortBy] = useState("")
    const [isSearched, setIsSearched] = useState(false);
    const [query, setQuery] = useState("")
    const onRemove = () => {
        setCurrentPage(1)
        dispatch(getPlaylistSongsAsync({ start: (0) * LIMIT, limit: LIMIT, sortBy }))
        dispatch(updateTab({ tab: 'Playlist' }))
    }
    useEffect(() => {
        dispatch(updateTab({ tab: 'Playlist' }))
        if (!songs || songs.length === 0) {
            dispatch(getPlaylistSongsAsync({ start: (0) * LIMIT, limit: LIMIT, sortBy }))
        }
    }, [])
    useEffect(() => {
        if(!isSearched){
            dispatch(getPlaylistSongsAsync({ start: (currentPage - 1) * LIMIT, limit: LIMIT, sortBy, append:true }))
        }
        else{
            dispatch(searchPlaylistSongsAsync({ query, start: (currentPage - 1) * LIMIT, limit: LIMIT, sortBy }))
        }
    }, [currentPage])
    const onSearch = (value) => {
        setCurrentPage(1)
        dispatch(searchPlaylistSongsAsync({ query: value }))
        setIsSearched(true)
        setQuery(value)
        dispatch(updateTab({ tab: 'Search Playlist' }))
    }
    const onClear = () => {
        setCurrentPage(1)
        dispatch(getPlaylistSongsAsync({ start: (0) * LIMIT, limit: LIMIT }))
        setIsSearched(false)
        setQuery("")
        dispatch(updateTab({ tab: 'Playlist' }))
    }
    const onSort = (field) => {
        setCurrentPage(1)
        setSortBy(field)
        if (isSearched) {
            dispatch(searchPlaylistSongsAsync({ query, start: (0) * LIMIT, limit: LIMIT, sortBy: field }))
        }
        else {
            dispatch(getPlaylistSongsAsync({ start: (0) * LIMIT, limit: LIMIT, sortBy: field }))
        }
    }
    const language = useSelector(selectLanguage)
    return <div className={styles.playlist}>
        <SearchBox placeholder={constants[language].placeholders.search_placeholder_songs}
            onSearch={onSearch}
            onClear={onClear}
        />
        <>
            <div className={styles.table}>
                <Table songs={songs} isPlaylist={true}
                    onRemove={onRemove}
                    isSortable={true}
                    sortBy={sortBy}
                    onSort={onSort}
                    currentPage={currentPage}
                    totalPage={totalPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>
            {/* <span className={styles.pagination}>
                {songs && songs.length > 0 && <Pagination totalPages={totalPage}
                    currentPage={currentPage} onPageChange={onPageChange}
                />}
            </span>
            <span className={styles.mobilePagination}>
                {songs && songs.length > 0 && <Pagination totalPages={totalPage}
                    currentPage={currentPage} onPageChange={onPageChange}
                    max={4}
                />}
            </span> */}
        </>
    </div>
}