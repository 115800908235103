import React, { useState, useRef } from 'react';
import styles from '../../../styles/component-styles/general/dragable-area.module.scss';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../redux/slice/language/laguage.slice';
import { constants } from '../../../constants';

const DragDropArea = ({ onFileUpload }) => {
    const language = useSelector(selectLanguage)
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);  // Reference to the hidden file input

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            onFileUpload(e.dataTransfer.files);
            e.dataTransfer.clearData();
        }
    };

    const handleFileSelect = (e) => {
        onFileUpload(e.target.files);
        e.target.value = ""
    };

    const handleClick = () => {
        // Trigger the hidden file input click event
        fileInputRef.current.click();
    };

    return (
        <div
            className={`${styles.dragDropArea} ${isDragging ? styles.dragging : ''}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleClick}  // Trigger file dialog on click
        >
            <div className={styles.icon}>🗃️</div>
            <div className={styles.messageContainer}>
                <div className={styles.message}>{constants[language].placeholders.attachments_dragdrop_msg}</div>
                <div className={styles.subMessage}>{constants[language].placeholders.attachments_size_msg}</div>
                <div className={styles.subMessage} style={{ color: "inherit" }} >{constants[language].placeholders.attachments_support_files_msg}</div>
            </div>
            <input
                type="file"
                multiple
                onChange={handleFileSelect}
                style={{ display: 'none' }}
                ref={fileInputRef}  // Attach the reference to the input element
            />
        </div>
    );
};

export default DragDropArea;
