import styles from '../../../../styles/component-styles/admin/compose-email-modal.module.scss';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersAsync, selectUsersData, selectUsersLoading } from "../../../../redux/slice/user/user-slice";
import close_ic from "../../../../assets/icons/close.svg";
import { PAGE_LIMIT_FOR_USERS_FOR_EMAIL } from "../../../../utils/constants";
import Center from '../../../general/Center';
import Checkbox from '../../../general/checkbox';
import { LoadingButton } from '../../../general/button/LoadingButton';
import { selectCreateEmailErrors, updateEmailErrors } from '../../../../redux/slice/email/email-slice';
import { selectLanguage } from '../../../../redux/slice/language/laguage.slice';
import { constants } from '../../../../constants';

const UserSearchInput = ({
    selectedParticipants = [], setSelectedParticipants = () => { }
}
) => {
    const dispatch = useDispatch();
    const [isInputFocused, setIsInputFocused] = useState(false,)
    const [searchTerm, setSearchTerm] = useState('')
    const [pagination, setPagination] = useState(null)
    const [loadedUsers, setLoadedUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isUsersListDropdownOpen, setIsUsersListDropdownOpen] = useState(false);
    const [showLess, setShowLess] = useState(true)
    const [checkedRows, setCheckedRows] = useState([]);

    const allUsers = useSelector(selectUsersData)
    const userLoading = useSelector(selectUsersLoading);
    const language = useSelector(selectLanguage)

    const searchInputRef = useRef(false)
    useEffect(() => {
        dispatch(getUsersAsync({ page: 1, limit: PAGE_LIMIT_FOR_USERS_FOR_EMAIL }))
    }, []);

    useEffect(() => {
        if (allUsers) {
            const { page } = allUsers?.pagination;
            if (page !== 1) {
                let updatedList = [...loadedUsers, ...allUsers?.users];

                setLoadedUsers(updatedList);
                setFilteredUsers(updatedList);
            } else {
                setLoadedUsers(allUsers?.users)

                // if items are selected and more than the page limit then reset it to be the page limit items
                if (selectedParticipants.length > PAGE_LIMIT_FOR_USERS_FOR_EMAIL)
                    setSelectedParticipants(allUsers?.users)
            }
            setPagination(allUsers?.pagination);
        }
        return () => {
            setLoadedUsers([])
        }
    }, [allUsers])

    // Filter users when searchTerm changes
    useEffect(() => {
        if (searchTerm) {
            const filtered = loadedUsers.filter((user) =>
                user?.name?.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers(loadedUsers);
        }
    }, [searchTerm, loadedUsers]);

    // Handle outside click to hide the user list
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                searchInputRef.current &&
                !searchInputRef.current.contains(event.target)
            ) {
                setIsUsersListDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchInputRef]);

    const handleShowMoreUsers = () => {
        dispatch(getUsersAsync({
            page: pagination?.page + 1,
            limit: PAGE_LIMIT_FOR_USERS_FOR_EMAIL,
        })
        );
    };
    const handleUnCheckAll = () => {
        setCheckedRows([]);
        setSelectedParticipants([]);
        setShowLess(true)
    };

    const handleCheckAll = () => {
        // Create a Set of current selected user IDs to avoid duplicates
        const selectedIds = new Set(selectedParticipants?.map((user) => user._id));

        // Filter out users from filteredParticipants that are already selected
        const newUsers = filteredUsers?.filter(
            (user) => !selectedIds.has(user._id)
        );
        // Update checkedRows and selectedParticipants without duplicates
        setCheckedRows([...selectedIds, ...newUsers.map((user) => user._id)]);
        setSelectedParticipants([...selectedParticipants, ...newUsers]);
    };

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        const name = event.target.name;
        const index = parseInt(name, 10); // Parsing to integer
        const user = filteredUsers[index];
        if (checked) {
            handleUserAdd(user); // To update the list by adding the recently added checked user
            if (!checkedRows.some((id) => id === user._id)) {
                const newRows = [...checkedRows, user._id];
                setCheckedRows(newRows);
            }
        } else {
            handleUserRemove(user); // To update the list by removing the recently unchecked user
        }
    };

    const handleUserAdd = (user) => {
        if (
            !selectedParticipants.some((participant) => participant._id === user._id)
        ) {
            const newSelectedParticipants = [...selectedParticipants, user];
            setSelectedParticipants(newSelectedParticipants);
        }
    };

    const handleUserRemove = (user) => {
        const updatedParticipants = selectedParticipants?.filter(
            (participant) => {
                return participant._id !== user._id;
            }
        );
        setSelectedParticipants(updatedParticipants);
        setCheckedRows(checkedRows.filter((rowId) => rowId !== user._id));
    };


    // Move `showData` calculation out of JSX
    const showData = selectedParticipants?.length > PAGE_LIMIT_FOR_USERS_FOR_EMAIL && showLess
        ? selectedParticipants.slice(0, PAGE_LIMIT_FOR_USERS_FOR_EMAIL)
        : selectedParticipants;
    return (
        <div ref={searchInputRef} className={styles.usersSearchInputContainer}>
            <div className={`${styles.input}`} style={{ border: isInputFocused ? "1px solid #EE4950" : "" }}>
                {showData?.length > 0 && <div className={styles.tagsContainer}>

                    {showData?.map((user, index) => (
                        <span key={index} className={styles.tags}>
                            {user?.name}
                            <img
                                src={close_ic} // You will replace 'close_ic' with your close icon's path
                                className={styles.tagClose}
                                alt="Remove"
                                onClick={() => handleUserRemove(user)}
                            />
                        </span>
                    ))}
                    {selectedParticipants?.length > PAGE_LIMIT_FOR_USERS_FOR_EMAIL && (showLess ? <span className={styles.viewAllLess} onClick={() => setShowLess(false)}>{constants[language].labels.view_all}</span> : <span className={styles.viewAllLess} onClick={() => setShowLess(true)}>{constants[language].labels.view_less}</span>)}
                </div>}

                <input
                    type="search"
                    placeholder={constants[language].labels?.recipients}
                    autoFocus={false}
                    onFocus={() => {
                        setIsInputFocused(true);
                        setIsUsersListDropdownOpen(true); // Open the dropdown when focused
                        dispatch(updateEmailErrors({ participants: null }));
                    }}
                    onBlur={() => setIsInputFocused(false)}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}

                />
            </div>
            {isUsersListDropdownOpen && <div className={styles.users_dropdown_list}>
                <div className={styles.selectUnselectContainer}>
                    {selectedParticipants?.length > 0 && (
                        <div className={styles.select_all}>
                            <span onClick={handleUnCheckAll}>
                                {constants[language].labels?.unselect_all}
                                ({selectedParticipants?.length})
                            </span>
                        </div>
                    )}
                    {checkedRows?.length !== loadedUsers?.length && (
                        <div className={styles.select_all}>
                            <span onClick={handleCheckAll}>
                                {constants[language].labels?.select_all}
                                ({filteredUsers?.length})
                            </span>
                        </div>
                    )}
                </div>
                {!filteredUsers?.length ? <Center style={{ paddingBottom: "12px" }}>
                    <div>{constants[language].labels?.no_record_found}</div>
                    <div className={styles.show_more_container}>
                        <LoadingButton
                            isLoading={userLoading}
                            onClick={() => dispatch(getUsersAsync({}))}
                        >
                            {constants[language].labels?.refech}
                        </LoadingButton>
                    </div>
                </Center> : filteredUsers?.map((user, index) => {
                    const isChecked = checkedRows.includes(user._id);
                    return (
                        <div
                            key={index}
                            className={`${styles.user_cotainer} ${isChecked ? styles.selected_row : ""
                                }`}
                            onClick={() =>
                                handleCheckboxChange({
                                    target: { checked: !isChecked, name: index.toString() },
                                })
                            }
                        >
                            <div className={styles.left}>
                                <div className={styles.name_container}>
                                    <div className={styles.name}>{user?.name}</div>
                                    <div className={styles.sub_text}>{user?.email}</div>
                                </div>
                            </div>
                            <div className={styles.right}>
                                <Checkbox
                                    isChecked={isChecked}
                                    onChange={handleCheckboxChange}
                                    value={isChecked}
                                    name={index}
                                    id={user._id}
                                    label={""}
                                />
                            </div>
                        </div>
                    );
                })}
                {pagination?.totalRecords >
                    pagination?.page * pagination?.limit &&
                    searchTerm?.length == 0 && (
                        <div className={styles.show_more_container}>
                            <LoadingButton
                                width={20}
                                isLoading={userLoading}
                                onClick={handleShowMoreUsers}
                            >
                                {constants[language].labels?.load_more}
                            </LoadingButton>
                        </div>
                    )}
            </div>}
        </div>
    )
}

export default UserSearchInput;