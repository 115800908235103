import styles from '../../../../styles/component-styles/general/email-table.module.scss';
import trash_ic from '../../../../assets/icons/trash.svg';
import show_ic from '../../../../assets/icons/show.svg';
import no_data from "../../../../assets/images/no_data.svg"
import loader from "../../../../assets/gifs/loader.webp"
import { constants } from '../../../../constants';
import { selectLanguage } from '../../../../redux/slice/language/laguage.slice';
import { useSelector } from 'react-redux';
import { convertDatetimeFormat } from '../../../../utils/convert-datetime-formet';
import { useInView } from "react-intersection-observer";
import { useEffect } from 'react';
// import styles from '../../../styles/component-styles/general/table.module.scss';

export function EmailsTable({ data, setCurrentPage, currentPage, totalPages, onView = () => { }, onRemove = () => { } }) {
    const [ref, inView] = useInView({ triggerOnce: false });
    const language = useSelector(selectLanguage);

    useEffect(() => {
        if (inView && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }, [inView, currentPage, totalPages, setCurrentPage]);

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th className={styles.head}>{constants[language].sr}</th>
                    <th className={styles.head}>{constants[language].labels.subject}</th>
                    <th className={styles.head}>{constants[language].labels.date_or_time}</th>
                    <th className={styles.head}>{constants[language].action}</th>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "fit-content" }}>
                    <td colSpan={4}>
                        <div className={styles.line}></div>
                    </td>
                </tr>
                
                {data?.length > 0 && data.map((singleEmail, index) => (
                    <tr key={index} ref={index === data.length - 1 ? ref : null}>
                        <td><div className={styles.no}>{index + 1}</div></td>
                        <td>
                            <div className={styles.title}>
                                {singleEmail?.subject?.length > 20 
                                    ? singleEmail?.subject?.substring(0, 20) + "..." 
                                    : singleEmail?.subject}
                            </div>
                        </td>
                        <td>
                            <div className={styles.date}>
                                {convertDatetimeFormat(singleEmail.sentAt)}
                            </div>
                        </td>
                        <td>
                            <div className={styles.action}>
                                <img src={trash_ic} alt="delete" onClick={() => onRemove(singleEmail)} />
                                <img src={show_ic} alt="view" onClick={() => onView(singleEmail)} />
                            </div>
                        </td>
                    </tr>
                ))}

                {data && data.length === 0 && (
                    <tr>
                        <td colSpan={4}>
                            <div className={styles.noData}>
                                <img src={no_data} alt="No data available" />
                                <p>{constants[language].no_data}</p>
                            </div>
                        </td>
                    </tr>
                )}

                {!data && (
                    <tr>
                        <td colSpan={4}>
                            <div className={styles.noData}>
                                <img src={loader} alt="Loading" />
                                <p>{constants[language].loading}</p>
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}


const ParticipantsList = ({ participants = [] }) => {
    return participants?.length > 0 ? (
        <div className={styles.participents_container}>
            {/* showing first three participents profile avatar and rest of them will be in more modal */}
            {participants
                ?.slice(0, 3)
                .map((items, index) => {
                    const imageStyle =
                        index === 0 ? styles.img_first : styles.img;
                    return (
                        <img
                            src={items.image}
                            style={{
                                left: `${-(index * 12)}px`,
                                // zIndex: -index,
                                // top: "-0.5px",
                            }}
                            className={imageStyle}
                            width={28}
                            height={28}
                            alt={"user"}
                            onClick={() => { alert("user avatar clicked") }}
                        />
                    );
                })}

            {/* if participents are more than three then add the more/plus avatar */}
            {participants?.length > 3 && (
                <div
                    className={styles.more_icon}
                    onClick={() => {
                        alert("More is clicked!");
                    }}
                    style={{
                        left: `${-(3 * 10)}px`,
                        // zIndex: -3,

                    }}
                >
                    {" "}
                    +{participants?.length - 3}
                </div>
            )}
        </div>
    ) : (
        <div>No participants added</div>
    )

}