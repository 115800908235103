import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { apiDELETE, apiGET } from "../../../apis/service";
import { toast } from "react-toastify";
import { PAGE_LIMIT_FOR_EMAILS } from "../../../utils/constants";
let BASE_URL = process.env.REACT_APP_BACKEND_URL ?? "http://localhost:4000";

// used to create single email
export const createEmailAsync = createAsyncThunk(
    "email/create",
    async ({ formData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(createEmailStart());
            const res = await fetch(`${BASE_URL}/api/v1/emails/create`, {
                method: "POST",
                body: formData,
                headers: {
                    Authorization:
                        "Bearer " + localStorage.getItem("token"),
                },
            });
            if (!res.ok) {
                toast.error("Error occured while sending email!");
                thunkAPI.dispatch(resetStates());
                return res;
            }
            let response = await res.json();

            toast.success("Email sent successfully!");
            thunkAPI.dispatch(createEmailSuccess(response.email));
            return res;
        } catch (error) {
            thunkAPI.dispatch(resetStates());
            toast.error(error.message)
            return error
        }
    }
);

export const getEmailsAsync = createAsyncThunk(
    "email/get",
    async ({ page = 1, limit = PAGE_LIMIT_FOR_EMAILS }, thunkAPI) => {
        try {
            thunkAPI.dispatch(getEmailsStart());
            const response = await apiGET("api/v1/emails", { page, limit });
            if (response?.success) {
                thunkAPI.dispatch(getEmailsSuccess(response));
            }
            else
                toast.error(response?.message);
            return response
        } catch (error) {
            thunkAPI.dispatch(resetStates());
            toast.error(error?.message);
            return error
        }
    }
)

export const getEmailAsync = createAsyncThunk(
    "email/getbyId",
    async ({ email_id }, thunkAPI) => {
        try {
            thunkAPI.dispatch(getEmailStart());
            const response = await apiGET(`api/v1/emails/${email_id}`);
            if (response.success) {
                thunkAPI.dispatch(getEmailSuccess(response));
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            thunkAPI.dispatch(resetStates());
            toast.error(error.message);
            return error
        }
    }
)

export const deleteEmailAsync = createAsyncThunk(
    "email/deletebyId",
    async ({ id }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(getEmailStart());
            const response = await apiDELETE(`api/v1/emails/${id}`);
            if (response.success) {
                toast.success("Email deleted successfully!");
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            // thunkAPI.dispatch(resetStates());
            toast.error(error.message);
            return error
        }
    }
)

const emailSlice = createSlice({
    name: "emails",
    initialState: {
        loading: false,
        error: null,
        email: null,
        emails: null,

        emailEditorRemovedImagesUrls: [],
        emailEditorImagesUrls: [],
        createEmailErrors: {
            participants: null,
            subject: null,
            body: null
        }
    },
    reducers: {
        getEmailsStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        getEmailsSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.emails = action.payload;
        },
        getEmailStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        getEmailSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.email = action.payload.email;
        },
        createEmailStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        createEmailSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.email = action.payload;
        },

        resetStates: (state) => {
            state.loading = false;
            state.error = null;
        },

        addEmailEditorAttachment: (state, action) => {
            state.emailEditorImagesUrls.push(action.payload);
        },
        removeEmailEditorAttachment: (state, action) => {
            const index = state.emailEditorImagesUrls.indexOf(action.payload);
            if (index > -1) {
                const removeItem = state.emailEditorImagesUrls[index];
                state.emailEditorRemovedImagesUrls.push(removeItem);
                state.emailEditorImagesUrls.splice(index, 1);
            }
        },
        resetEmailEditorRemovedImagesUrls: (state) => {
            state.emailEditorRemovedImagesUrls = [];
        },
        resetEmailEditorAtachment: (state) => {
            state.emailEditorImagesUrls = [];
        },

        updateEmailErrors: (state, action) => {
            const { participants, subject, body } = action.payload;
            state.createEmailErrors = {
                participants: participants ?? null,
                subject: subject ?? null,
                body: body ?? null
            }
            // state.createEmailErrors = action.payload;
        },
        resetEmailErrors: (state) => {
            state.createEmailErrors = {
                participants: null,
                subject: null,
                body: null
            }
        },
        // reset email
        setEmailData: (state, action) => {
            state.email = action?.payload ?? null;
        }
    }
})

export const {
    getEmailsStart, getEmailsSuccess,
    getEmailStart, getEmailSuccess, setEmailData,
    createEmailStart, createEmailSuccess, resetStates,
    updateEmailErrors, resetEmailErrors,
    addEmailEditorAttachment, removeEmailEditorAttachment, resetEmailEditorAtachment, resetEmailEditorRemovedImagesUrls,
} = emailSlice.actions;

export const selectEmailData = state => state.emails.email;
export const selectEmailsData = state => state.emails.emails;
export const selectCreateEmailErrors = state => state.emails.createEmailErrors;
export const selectEmailEditorImagesUrls = state => state.emails.emailEditorImagesUrls;
export const selectEmailRemovedImagesUrls = state => state.emails.emailEditorRemovedImagesUrls;

// loading
export const selectEmailsLoading = state => state.emails.loading;
export const selectCreateingEmailLoading = state => state.emails.loading;

export default emailSlice.reducer