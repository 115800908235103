import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiPOST, apiPOST_Tokenless, apiGET, apiPOST_File } from "../../../apis/service";
import { toast } from "react-toastify";
export const uploadSongAsync = createAsyncThunk(
    "song/upload",
    async ({ file }, thunkAPI) => {
        //implement upload song
        const BATCH_SIZE = 100;
        try {
            const formData = new FormData()
            const reader = new FileReader()
            reader.readAsText(file)
            let records = [];
            reader.onload = async function (event) {
                const csvData = event.target.result;
                const rows = csvData.split('\n').slice(1); // Skip header row
                thunkAPI.dispatch(uploadingStart())
                const firstLine = csvData.split('\n')[0]
                for (let i = 0; i < rows.length; i++) {
                    // Parse CSV row
                    const row = rows[i];

                    // Append record to batch
                    records.push(row);

                    // If batch size reached or end of file, send batch
                    if (records.length === BATCH_SIZE || i === rows.length - 1) {
                        const filename = (Math.random() * 1000).toFixed(0)
                        const batchFile = new File([firstLine, ...records], `${filename}.csv`, { type: "text/csv" })
                        formData.append('file', batchFile);

                        // Dispatch upload start action
                        thunkAPI.dispatch(uploadSongsStart());

                        // Upload the batch
                        const response = await apiPOST_File("upload-songs", formData);
                        // Dispatch actions based on response
                        if (response.success) {
                            // If successful, dispatch success action
                            thunkAPI.dispatch(uploadSongsSuccess(response));
                            thunkAPI.dispatch(setStartingId(response.startingId))
                            thunkAPI.dispatch(setLastId(response.lastId))
                            thunkAPI.dispatch(addLength(response.length))
                            // sessionStorage.setItem('progress', i*100/rows.length)
                            thunkAPI.dispatch(addProgress(i * 100 / rows.length))
                            // toast.success("upload Successful")
                        } else {
                            // If failed, dispatch fail action and break loop
                            thunkAPI.dispatch(uploadSongsFail(response.message));
                            toast.error("Upload Failed! Check network and try again.");
                            break;
                        }

                        // Clear formData for the next batch
                        formData.delete('file');
                        records = [];
                    }
                }
                reader.onerror = function (error) {
                    toast.error("Error reading file: " + error.message);
                    return
                };
            }
            toast.success("Uploaded songs successfully!")
        }
        catch (error) {
            toast.error(error.message);
        }
    }
)
export const cancelUploadSongAsync = createAsyncThunk(
    "song/cancel",
    async ({ startingId, lastId }, thunkAPI) => {
        try {
            const response = await apiPOST("cancel-upload", { startingId, lastId });
            if (response.success) {
                toast.success("Song upload cancelled");
            }
            else {
                toast.error(response.message);
            }
            return response
        }
        catch (error) {
            toast.error(error.message);
        }
    }
)
export const topSongsAsync = createAsyncThunk(
    "song/top",
    async ({ no = 6 }, thunkAPI) => {
        try {
            thunkAPI.dispatch(topSongsStart());
            const response = await apiGET("top-songs", { no });
            if (response.success)
                thunkAPI.dispatch(topSongsSuccess(response));
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)
export const getSongsAsync = createAsyncThunk(
    "song/get",
    async ({ start, limit, sortBy, append }, thunkAPI) => {
        try {
            if (!append) {
                thunkAPI.dispatch(getSongsStart());
            }
            const response = await apiGET("songs", { start, limit, sortBy });
            if (response.success) {
                if (!append)
                    thunkAPI.dispatch(getSongsSuccess(response));
                else
                    thunkAPI.dispatch(appendResult(response));
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)

export const getNewSongsAsync = createAsyncThunk(
    "song/get",
    async ({ start, limit, sortBy, append }, thunkAPI) => {
        try {
            if (!append) {
                thunkAPI.dispatch(getSongsStart());
            }
            const response = await apiGET("new-songs", { start, limit, sortBy });
            if (response.success) {
                if (!append)
                    thunkAPI.dispatch(getSongsSuccess(response));
                else
                    thunkAPI.dispatch(appendResult(response));
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)
export const getSongAsync = createAsyncThunk(
    "song/getbyId",
    async ({ id }, thunkAPI) => {
        try {
            thunkAPI.dispatch(getSongStart());
            const response = await apiGET(`song/${id}`);
            if (response.success)
                thunkAPI.dispatch(getSongSuccess(response));
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)
export const searchSongAsync = createAsyncThunk(
    "song/search",
    async ({ query, start, limit, sortBy, append }, thunkAPI) => {
        try {
            if (!append)
                thunkAPI.dispatch(searchSongStart());
            const response = await apiGET("search-songs", { query, start, limit, sortBy });
            if (response.success) {
                if (!append)
                    thunkAPI.dispatch(searchSongSuccess(response));
                else
                    thunkAPI.dispatch(appendResult(response));
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)
const songSlice = createSlice({
    name: "song",
    initialState: {
        songs: null,
        topSongs: null,
        totalPages: 0,
        song: {},
        numberOfUploads: 0,
        startingId: null,
        lastId: null,
        lastUpdated: null,
        loading: false,
        error: null,
        uploadingSongs: false,
        progress: 0
    },
    reducers: {
        uploadingStart: (state) => {
            state.progress = 0;
            state.startingId = null;
            state.lastId = null;
            state.numberOfUploads = 0;
        },
        uploadSongsStart: (state) => {
            state.loading = true;
            state.error = null;
            state.uploadingSongs = true;

        },
        uploadSongsSuccess: (state, action) => {
            state.lastUpdated = Date.now();
            state.loading = false;
            state.error = null;
            state.uploadingSongs = false;
        },
        uploadSongsFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.uploadingSongs = false;
        },
        getSongsSuccess: (state, action) => {
            state.songs = action.payload.songs;
            state.totalPages = action.payload.totalPages;
            state.loading = false;
            state.error = null;
        },
        getSongSuccess: (state, action) => {
            state.song = action.payload.song;
            state.loading = false;
            state.error = null;
        },
        appendResult: (state, action) => {
            if (!state.songs) {
                state.songs = action.payload.songs;
            }
            else {
                let count = 0;
                for (let i = 0; i < action.payload.songs.length; i++) {
                    const song1 = action.payload.songs[i]
                    if (state.songs.find(song => song._id == song1._id)) {
                        count++;
                    }
                }
                if (count < action.payload.songs.length)
                    state.songs = [...state.songs, ...action.payload.songs];
            }
            state.totalPages = action.payload.totalPages;
            state.loading = false;
            state.error = null;
        },
        searchSongSuccess: (state, action) => {
            state.songs = action.payload.songs;
            state.totalPages = action.payload.totalPages;
            state.loading = false;
            state.error = null;
        },
        topSongsSuccess: (state, action) => {
            state.topSongs = action.payload.songs;
            state.loading = false;
            state.error = null;
        },
        getSongsStart: (state) => {
            state.songs = null;
            state.loading = true;
            state.error = null;
        },
        setSongsData: (state, action) => {
            state.songs = action.payload ?? null;
            state.loading = false;
            state.error = null;
        },
        getSongStart: (state) => {
            state.song = {};
            state.loading = true;
            state.error = null;
        },
        searchSongStart: (state) => {
            state.songs = null;
            state.loading = true;
            state.error = null;
        },
        topSongsStart: (state) => {
            state.topSongs = null;
            state.loading = true;
            state.error = null;
        },
        addProgress: (state, action) => {
            state.progress = action.payload;
        },
        setStartingId: (state, action) => {
            if (!state.startingId) {
                state.startingId = action.payload
            }
        },
        setLastId: (state, action) => {
            state.lastId = action.payload
        },
        addLength: (state, action) => {
            state.numberOfUploads += action.payload
        }
    }
})
export const { getSongsSuccess, getSongSuccess, searchSongSuccess, topSongsSuccess,
    uploadSongsStart, uploadSongsSuccess, uploadSongsFail, getSongsStart, setSongsData, getSongStart, searchSongStart, topSongsStart,
    addProgress, setStartingId, setLastId, addLength, uploadingStart, appendResult
} = songSlice.actions
export const selectSongs = state => state.song.songs
export const selectSong = state => state.song.song
export const selectTopSongs = state => state.song.topSongs
export const selectTotalPages = state => state.song.totalPages
export const selectNumberOfUploads = state => state.song.numberOfUploads
export const selectStartingId = state => state.song.startingId
export const selectUploadingSongs = state => state.song.uploadingSongs
export const selectLastId = state => state.song.lastId
export const selectLastUpdated = state => state.song.lastUpdated
export const selectProgress = state => state.song.progress
export default songSlice.reducer