import { lighten } from 'polished';
import styled, { keyframes } from 'styled-components';
import Loader from '../Loader';

const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  gap: 0.3em;
  width: ${(props) => `${props.width || 100}%`};
  height: ${(props) => `${`${props.height}%` || "auto"}`};
  background-color: ${(props) => props.theme.colors[props.variant] || props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primaryText};
  padding: ${(props) => props.theme.spacing.sm} ${(props) => props.theme.spacing.md};
  
  // If rounded prop is true, make it fully rounded, otherwise apply the small radius
  border-radius: ${(props) => (props.rounded ? '50px' : props.theme.borderRadius.sm)};
  
  font-family: ${(props) => props.theme.typography.fontFamily};
  // font-size: ${(props) => props.theme.typography.fontSizeBase};
  transition: ${(props) => props.theme.transition.fast};

  &:hover {
    background-color: ${(props) =>
    props.variant === 'primary' && lighten(0.1, props.theme.colors.primary)};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.primaryText};
    cursor: not-allowed;
  }

  &:active {
    transform: scale(0.95);
  }

  // Responsive styles for smaller screens
  @media (max-width: 768px) {
    font-size: 0.875rem; // Adjust this size as needed for tablet screens
  }

  @media (max-width: 480px) {
    font-size: 0.75rem; // Adjust this size as needed for mobile screens
  }
`;

export function Button({ children, icon, variant = 'primary', isLoading, rounded = false, ...props }) {
  return (
    <StyledButton variant={variant} disabled={isLoading} rounded={rounded} {...props}>
      {isLoading ? <Loader /> : icon && icon}
      {!isLoading && children}
    </StyledButton>
  );
};
