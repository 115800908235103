import React from "react";
import styles from "../../../styles/component-styles/general/email-editor.module.scss"
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import MyCustomUploadAdapterPlugin from "../../../utils/customUpload";
import { useDispatch, useSelector } from "react-redux";
import { removeEmailEditorAttachment, selectEmailEditorImagesUrls } from "../../../redux/slice/email/email-slice";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { constants } from "../../../constants";
function EmailEditor({ editorData, setEditorData, onFocus = () => { } }) {
  const fontSizeOptions = [
    { title: "8px", model: "8px" },
    { title: "12px", model: "12px" },
    { title: "14px", model: "14px" },
    { title: "16px", model: "16px" },
    { title: "18px", model: "18px" },
    { title: "20px", model: "20px" },
    { title: "24px", model: "24px" },
    { title: "28px", model: "28px" },
    { title: "30px", model: "30px" },
    { title: "32px", model: "32px" },
    { title: "36px", model: "36px" },
    { title: "40px", model: "40px" },
    { title: "48px", model: "48px" },
    { title: "56px", model: "56px" },
    { title: "64px", model: "64px" },
    { title: "72px", model: "72px" },
  ];

  const emailEditorInsideAatachmentsUrls = useSelector(selectEmailEditorImagesUrls)
  const language = useSelector(selectLanguage)

  const dispatch = useDispatch()
  return (
    <div className={styles.ck_editor}>
      <CKEditor
        editor={Editor}
        data={editorData}
        onReady={(editor) => {
          // Insert the toolbar before the editable area.
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
            );
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorData(data);

          // Extract current images in the editor content
          const parser = new DOMParser();
          const doc = parser.parseFromString(data, "text/html");
          const currentImages = Array.from(doc.querySelectorAll("img")).map((img) => img.getAttribute("src"));
          // Compare current images with the Redux state

          emailEditorInsideAatachmentsUrls?.forEach((imageUrl) => {
            if (!currentImages.includes(imageUrl)) {
              // If the image is in Redux but not in the editor, remove it from Redux
              dispatch(removeEmailEditorAttachment(imageUrl));
            }
          });
        }}
        onBlur={(event, editor) => { }}
        onFocus={(event, editor) => {
          onFocus();
        }}
        config={{
          toolbar: {
            items: [
              "fontColor",
              "alignment",
              "link",
              "uploadImage",
              "uplaodFile",
              "highlight",
              "bold",
              "italic",
            ],
            shouldNotGroupWhenFull: false,
            // containerClass: { toolbarstyle },
          },
          image: {
            toolbar: [
              "imageTextAlternative",
              "toggleImageCaption",
              "imageStyle:inline",
              "imageStyle:block",
              "imageStyle:side",
              "resizeImage",
            ],
          },
          extraPlugins: [MyCustomUploadAdapterPlugin],
          additionalParams: { dispatchFun: dispatch, purpose: "email" },
          placeholder: constants[language]?.placeholders.email_content,
          fontSize: {
            options: fontSizeOptions,
          },
        }}
      />
    </div>
  );
}

export default EmailEditor;
